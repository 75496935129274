import {
  CreateContextProvider,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  useCreateController,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

export const ProductCreate = (props: any) => {
  const controller: any = useCreateController({
    resource: "products/crud",
  });

  return (
    <>
      <CreateContextProvider value={controller}>
        <SimpleForm>
          <PageTopBreadcrumb
            title="Item de Cobrança"
            firstPath="/#/products/crud"
            secondTitle="Novo"
          />
          <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
            <CardHeader title="Criar" />
            <CardContent>
              <Grid container spacing={0.5}>
                <Grid item xs={12} lg={12}>
                  <TextInput
                    source="name"
                    fullWidth
                    label="Nome"
                    helperText={false}
                  />
                  <TextInput
                    source="image"
                    fullWidth
                    label="Imagem"
                    helperText={false}
                  />
                  <NumberInput
                    source="price"
                    helperText="Use . para centavos"
                    label="Preço"
                  />
                  <NumberInput
                    source="maxInstallments"
                    label="Máximo de Parcelas"
                    fullWidth
                  />
                  <RichTextInput
                    source="description"
                    fullWidth
                    label="Descrição"
                  />
                  <SelectInput
                    source="status"
                    label="Situação"
                    fullWidth
                    choices={[
                      { id: "active", name: "Ativo" },
                      { id: "inactive", name: "Inativo" },
                    ]}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </SimpleForm>
      </CreateContextProvider>
    </>
  );
};
