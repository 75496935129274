import React, { useEffect, useState } from "react";
import {
  Form,
  useDataProvider,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Button,
  Alert,
  Box,
} from "@mui/material";

type Product = {
  id: string;
  name: string;
  price: number;
  description?: string;
  maxInstallments?: number;
};

const IuguClientIssueBill = () => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [user, setUser] = useState<any>(null);
  const [userIuguId, setUserIuguId] = useState<string | null>(null);
  const [parcel, setParcel] = useState(0);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const getUserIuguId = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const { data } = await dataProvider.getOne("users/crud", {
      id: authJson.id,
    });
    if (data.iuguId) {
      setUserIuguId(data.iuguId);
    }
    setUser(data);
  };

  useEffect(() => {
    getUserIuguId();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      setTotalPrice(selectedProduct.price);
    } else {
      setTotalPrice(0);
    }
  }, [selectedProduct]);

  const handleSubmit = async () => {
    if (!selectedProduct) {
      notify("Por favor selecione um produto", { type: "error" });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("product_id", selectedProduct.id);
      formData.append("customer_id", userIuguId || "");
      formData.append("user_id", user.id);
      formData.append("email", user.email);
      formData.append("price", totalPrice.toString());
      formData.append(
        "max_installments_value",
        selectedProduct?.maxInstallments?.toString() || "1"
      );
      const auth = localStorage.getItem("auth");
      const authJson = auth ? JSON.parse(auth) : auth;
      console.log("formData", formData);
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/iugu/invoices/checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authJson.access_token,
          },
          body: JSON.stringify({
            product_id: selectedProduct.id,
            customer_id: userIuguId,
            user_id: user.id,
            email: user.email,
            price: totalPrice,
            max_installments_value:
              selectedProduct?.maxInstallments?.toString() || "1",
          }),
        }
      );
      const data = await response.json();
      console.log("data", data);
      notify(
        "Fatura criada com sucesso, redirecionando para a lista de faturas",
        {
          type: "success",
        }
      );
      setTimeout(() => {
        window.location.href = "/#/my-invoices";
      }, 3000);
    } catch (error) {
      console.error("Error creating bill:", error);
      notify("Erro ao criar fatura", { type: "error" });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card sx={{ padding: 2, marginBottom: 3 }}>
        <CardHeader title="Criar Nova Fatura" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReferenceInput
                source="productId"
                reference="products/crud"
                perPage={10}
                fullWidth
              >
                <AutocompleteInput
                  label="Selecione um item"
                  optionText="name"
                  fullWidth
                  onChange={async (productId) => {
                    if (productId) {
                      try {
                        const { data } = await dataProvider.getOne(
                          "products/crud",
                          {
                            id: productId,
                          }
                        );
                        console.log("data", data);
                        setParcel(
                          Number(data.price) / Number(data.maxInstallments)
                        );
                        setSelectedProduct(data);
                      } catch (error) {
                        console.error("Error fetching product:", error);
                        notify("Erro ao carregar produto", { type: "error" });
                      }
                    } else {
                      setSelectedProduct(null);
                    }
                  }}
                />
              </ReferenceInput>
            </Grid>

            {selectedProduct && selectedProduct.description && (
              <Grid item xs={12}>
                <Alert severity="info">
                  {selectedProduct.description && (
                    <Box
                      sx={{
                        "& a": { color: "primary.main" },
                        "& p": { margin: "0 0" },
                        "& ul, & ol": { marginLeft: 2 },
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedProduct.description,
                        }}
                      />
                    </Box>
                  )}
                </Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6">
                Valor Total: R$ {totalPrice.toFixed(2).replace(".", ",")}
              </Typography>
              {selectedProduct?.maxInstallments &&
                selectedProduct?.maxInstallments > 1 && (
                  <Typography variant="body1">
                    Parcelado em até {selectedProduct?.maxInstallments}x de R$
                    {parcel.toFixed(2).replace(".", ",")}
                  </Typography>
                )}
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!selectedProduct}
              >
                Criar Fatura
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default IuguClientIssueBill;
