import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useEditController,
  AutocompleteArrayInput,
  SimpleForm,
  FunctionField,
  EditContextProvider,
  ReferenceArrayInput,
  SingleFieldList,
  ArrayField,
  ArrayInput,
  DateInput,
  TextInput,
  SelectInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  ChipField,
  AutocompleteInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import TextFieldMUI from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import { useDataProvider } from "react-admin";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { ZIPComponent } from "./ZIPComponent";
import { formatCNPJ, formatCPF } from "./CPFUtils";
import { validateUserResource } from "./UserValidation";
import { JsonInput } from "react-admin-json-view";
import FileUploadComponent from "./FileUploadComponent";
import { CreateGraduation } from "./CreateGraduationModal";
import { CreateEducationalInstitution } from "./CreateEducationalInstitutionModal";
import { determineUserReadableRole } from "./UserUtils";
import { RichTextInput } from "ra-input-rich-text";

export const UserEdit = (props: any) => {
  // const editContext: any = useEditController();
  const { id } = useParams();
  const userRole = localStorage.getItem("roles");
  // console.log("userRole", userRole);

  const controller: any = useEditController({
    resource: "users/crud",
    id,
    redirect: "show",
    mutationMode: "pessimistic",
  });
  // controller.redirect = "show";
  const filterToQuery = (searchText: any) => ({
    name: `${searchText}`,
    // reviewed: "true",
  });
  const [selectedDTEs, setSelectedDTEs] = useState<any>([]);

  const { record } = controller;
  const dataProvider = useDataProvider();
  // const [roles, setRoles] = useState([]);
  const [imgPreview, setImgPreview] = useState<any>({});

  // useEffect(() => {
  //   fetchRoles();
  // }, []);
  // const required =
  //   (message = "Required") =>
  //   (value: any) =>
  //     value ? undefined : message;

  // const fetchRoles = async () => {
  //   try {
  //     const response = await dataProvider.getList("user-roles/crud", {
  //       pagination: { page: 1, perPage: 25 },
  //       sort: { field: "id", order: "DESC" },
  //       filter: {},
  //     });

  //     if (response && response.data) {
  //       setRoles(
  //         response.data.map((role: any) => ({
  //           id: role.id,
  //           name: role.name,
  //         })) as never[]
  //       );
  //       // console.log("roles", roles);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch roles: ", error);
  //   }
  // };
  const MyToolbar = () => (
    <Toolbar>
      <SaveButton type="button" />
      {determineUserReadableRole(userRole) === "admin" && <DeleteButton />}
    </Toolbar>
  );
  const handleChange = (changes: any) => {
    // console.log("Field changes:", changes);
    setSelectedDTEs(changes);
  };
  return (
    <EditContextProvider value={controller}>
      <SimpleForm validate={validateUserResource} toolbar={<MyToolbar />}>
        <PageTopBreadcrumb
          title="Sócios"
          firstPath="/#/users/crud"
          secondTitle="Editando"
          thirdTitle={
            controller && controller.record && controller.record.fullName
          }
          actionType="show"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader
            title="Informações Pessoais"
            // subheader="September 14, 2016"
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput
                  required
                  label="Nome"
                  source="fullName"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextInput
                  required
                  source="email"
                  label="Email Particular"
                  type="email"
                  fullWidth
                  // disabled={determineUserReadableRole(userRole) !== "admin"}
                  helperText={false}
                />
              </Grid>
              {/* <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="login"
                  label="login"
                  fullWidth
                  helperText={false}
                />
              </Grid> */}
              <Grid item lg={3} md={6} xs={12}>
                {/* <TextInput
                  required
                  source="password"
                  label="password"
                  type="password"
                  fullWidth
                  helperText={false}
                /> */}
                <TextFieldMUI
                  label="Senha"
                  fullWidth
                  value="********"
                  type="password"
                  disabled
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextInput
                  required
                  source="cpf"
                  disabled={determineUserReadableRole(userRole) !== "admin"}
                  label="CPF"
                  format={(v: any) => formatCPF(v)}
                  // parse={(v: any) => {
                  //   validarCPF(v);
                  // }}
                  fullWidth
                  helperText={false}
                />
                <TextInput
                  required
                  source="cnpj"
                  disabled={determineUserReadableRole(userRole) !== "admin"}
                  label="CNPJ"
                  format={(v: any) => formatCNPJ(v)}
                  // parse={(v: any) => {
                  //   validarCPF(v);
                  // }}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextInput
                  source="CREA"
                  label="CREA"
                  disabled={determineUserReadableRole(userRole) !== "admin"}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source="professionalActivity"
                  label="Atividade Profissional Principal"
                  disabled={determineUserReadableRole(userRole) !== "admin"}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <DateInput
                  source="birthDate"
                  label="Data de Nascimento"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <SelectInput
                  source="gender"
                  label="Sexo"
                  fullWidth
                  helperText={false}
                  choices={[
                    { id: "M", name: "Masculino" },
                    { id: "F", name: "Feminino" },
                  ]}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <SelectInput
                  source="maritalStatus"
                  label="Estado Civil"
                  fullWidth
                  helperText={false}
                  choices={[
                    { id: "SOLTEIRO", name: "Solteiro(a)" },
                    { id: "CASADO", name: "Casado(a)" },
                    { id: "DESQUITADO", name: "Desquitado(a)" },
                    { id: "DIVORCIADO", name: "Divorciado(a)" },
                    { id: "VIUVO", name: "Viúvo(a)" },
                    { id: "UNIAO_ESTAVEL", name: "União Estável" },
                  ]}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="phoneNumber"
                  label="Celular"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="residencePhoneNumber"
                  label="Telefone Residencial"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressState"
                  label="Estado"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <TextInput
                  source="address"
                  label="Endereço"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressCity"
                  label="Cidade"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <ZIPComponent />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressNeighborhood"
                  label="Bairro"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressRegion"
                  label="Região"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Label>Prévia</Label>
                <img src={"/" + imgPreview.path} width={100} alt="Prévia" />
              </Grid> */}

              {/* {determineUserReadableRole(userRole) === "admin" && ( */}
              <Grid item xs={12}>
                <FunctionField
                  render={(record: any) =>
                    (determineUserReadableRole(userRole) !== "admin" &&
                      record.dependents.length) ||
                    determineUserReadableRole(userRole) === "admin" ? (
                      <ArrayInput
                        source="dependents"
                        label="Dependentes"
                        disabled={
                          determineUserReadableRole(userRole) !== "admin"
                        }
                      >
                        <SimpleFormIterator
                          inline
                          fullWidth
                          getItemLabel={(index) => `#${index + 1}`}
                        >
                          <TextInput source="name" label="Nome" />
                          <TextInput source="birthDate" label="Nascimento" />
                          <TextInput source="kinship" label="Parentesco" />
                          {/* <TextInputMUI
                      name={`dependents[${index}].name`}
                      label={`Nome do Dependente #${index + 1}`}
                      fullWidth
                      helperText={false}
                    /> */}
                        </SimpleFormIterator>
                      </ArrayInput>
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid>
              {/* )} */}
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={3}>
          <Grid lg={8} xs={12} item>
            <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
              <CardHeader title="Informações Comerciais" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextInput
                      label="Email Comercial"
                      source="commercialEmail"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextInput
                      label="Telefone Comercial"
                      source="commercialPhoneNumber"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextInput
                      label="Endereço Comercial"
                      source="commercialAddress"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="Cidade Comercial"
                      source="commercialAddressCity"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="Bairro"
                      source="commercialAddressNeighborhood"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="Estado Comercial"
                      source="commercialAddressState"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="CEP Comercial"
                      source="commercialAddressZipCode"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid lg={4} xs={12} item>
            <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
              <CardHeader title="Arquivos e Mídia" />
              <CardContent>
                <Grid item xs={12}>
                  <div style={{ display: "none" }}>
                    <JsonInput source="physicalCardPicture" label="Foto" />
                  </div>
                  <FileUploadComponent
                    controller={controller}
                    id={id}
                    save={controller.save}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Formações" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ArrayInput
                  source="userGraduations"
                  label="Graduações do Membro"
                  disabled={determineUserReadableRole(userRole) !== "admin"}
                >
                  <SimpleFormIterator
                    inline
                    fullWidth
                    getItemLabel={(index) => `#${index + 1}`}
                  >
                    <ReferenceInput
                      source="graduationId"
                      reference="graduations/crud"
                      sort={{ field: "reviewed", order: "DESC" }}
                      perPage={3000}

                      // enableGetChoices={({ name }) => name && name.length >= 2}
                    >
                      <AutocompleteInput
                        label="Graduação"
                        optionText="name"
                        optionValue="id"
                        create={<CreateGraduation />}
                        suggestionLimit={50}
                        disabled={
                          determineUserReadableRole(userRole) !== "admin"
                        }
                        isRequired
                        // sort={{ field: 'name', order: 'ASC' }}
                        filterToQuery={filterToQuery}
                        sx={{ width: "20vw" }}
                      />
                    </ReferenceInput>

                    <ReferenceInput
                      source="educationalInstitutionId"
                      reference="educational-institutions/crud"
                      perPage={3000}

                      // perPage={10}
                      // enableGetChoices={({ name }) => name && name.length >= 2}
                    >
                      <AutocompleteInput
                        label="Instituição de Ensino"
                        optionText="name"
                        optionValue="id"
                        disabled={
                          determineUserReadableRole(userRole) !== "admin"
                        }
                        create={<CreateEducationalInstitution />}
                        isRequired
                        suggestionLimit={50}
                        filterToQuery={filterToQuery}
                        sx={{ width: "20vw" }}
                      />
                    </ReferenceInput>
                    <NumberInput
                      source="yearCompletion"
                      label="Ano de Conclusão"
                      isRequired
                      helperText={false}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Divisões técnicas" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">DTEs Atuais</Typography>
                <ArrayField label="DTEs" source="technicalDivisions">
                  <SingleFieldList linkType={false}>
                    <ChipField source="name" size="small" />
                  </SingleFieldList>
                </ArrayField>

                {/* user role admin only */}
                {determineUserReadableRole(userRole) === "admin" && (
                  <>
                    <Typography variant="subtitle2">
                      Atualizar DTEs para
                    </Typography>
                    <ReferenceArrayInput
                      reference="technical-divisions/crud"
                      source="technicalDivisions"
                    >
                      <AutocompleteArrayInput
                        optionText="name"
                        optionValue="id"
                        label="Divisões Técnicas"
                        filterToQuery={filterToQuery}
                        onChange={handleChange}
                        disabled={selectedDTEs.length >= 2}
                        helperText={
                          "Selecione até 2 divisões técnicas. " +
                          "Deixe em branco caso não se aplique. "
                        }
                        // create={<TechDivisionCreate showListActions={false} />}
                      />
                      {/* //TODO CREATION NOT WORKING */}
                      {/* //TODO NOT SELECTING EXISTING */}
                    </ReferenceArrayInput>
                    {selectedDTEs.length >= 2 && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setSelectedDTEs([]);
                          // empties the AutoCompleteArrayInput, clicks on a button element with class .MuiAutocomplete-clearIndicator
                        }}
                      >
                        Corrigir seleção
                      </Button>
                    )}
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        // console.log("inspect controller action", controller);
                        controller.record.technicalDivisions = [];
                        // console.log(
                        //   "inspect controller after",
                        //   controller.record.technicalDivisions
                        // );
                        // controller.save(controller.record);
                        controller.save(controller.record);
                      }}
                    >
                      Limpar DTEs
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {determineUserReadableRole(userRole) === "admin" && (
          <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
            <CardHeader title="Informações do Associado" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item lg={2} md={6} xs={12}>
                  <NumberInput
                    source="registrationNumber"
                    label="Matrícula"
                    helperText={false}
                    // disabled
                    fullWidth
                  />
                  {/* <FunctionField
                    render={(record: any) => (
                      <TextFieldMUI
                        value={record.registrationNumber}
                        label="Matrícula"
                        helperText={false}
                        fullWidth
                        // disabled
                      />
                    )}
                  /> */}
                </Grid>
                <Grid item lg={1} md={6} xs={12}>
                  <NumberInput
                    source="registrationNumberDigit"
                    label="Dígito"
                    helperText={false}
                    // disabled
                    fullWidth
                  />
                  {/* <TextInput
                    source="registrationNumberProv"
                    label="Matrícula Provisória"
                    helperText={false}
                    disabled
                    fullWidth
                  /> */}
                </Grid>
                <Grid item lg={2} md={6} xs={12}>
                  <SelectInput
                    source="accountStatus"
                    label="Situação da Conta"
                    fullWidth
                    helperText={false}
                    choices={[
                      { id: "ACTIVE", name: "Ativa" },
                      { id: "DECEASED", name: "Falecido" },
                      { id: "CANCELED", name: "Cancelado" },
                      { id: "LICENSED", name: "Licenciado" },
                      { id: "PENDING", name: "Pendente" },
                      { id: "NO_CONTACT", name: "Sem Contato" },
                      { id: "UNKNOWN", name: "Desconhecido" },
                    ]}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <ReferenceInput
                    source="userCategoryId"
                    reference="user-categories/crud"

                    // perPage={10}
                    // enableGetChoices={({ name }) => name && name.length >= 2}
                  >
                    <AutocompleteInput
                      label="Categoria do Membro"
                      disabled
                      optionText="name"
                      optionValue="id"
                      fullWidth
                      helperText={
                        "Alterações de categoria devem ser realizadas pelas operações em massa, devido a interação com os campos de data."
                      }
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item lg={2} md={6} xs={12}>
                  <ReferenceInput
                    source="roleId"
                    reference="user-roles/crud"

                    // perPage={10}
                    // enableGetChoices={({ name }) => name && name.length >= 2}
                  >
                    <AutocompleteInput
                      label="Regra de Usuário"
                      optionText="name"
                      optionValue="id"
                      fullWidth
                      helperText={false}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item lg={2} md={6} xs={12}>
                  <BooleanInput
                    source="hasPhysicalCard"
                    label="Possui Carteirinha"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <div className="richTextBody">
                    <RichTextInput
                      source="observation"
                      label="Observações"
                      helperText={false}
                      lineHeight={"0.5"}
                      // disabled
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
              <Alert severity="warning">
                <b>ATENÇÃO:</b> Campo de matrícula está temporariamente
                disponível para edição por admins, realize alterações com
                responsabilidade, certifique-se de não introduzir duplicações de
                matrícula ou matrículas fora de sequência!
              </Alert>
            </CardContent>
          </Card>
        )}
      </SimpleForm>
    </EditContextProvider>
  );
};
