import {
  BulkDeleteButton,
  useListController,
  BulkUpdateButton,
  useNotify,
} from "react-admin";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Box, Button, Divider } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import Select from "@mui/material/Select";
import ListItemButton from "@mui/material/ListItemButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Card } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Alert from "@mui/material/Alert";
import MuiList from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { FaCog } from "react-icons/fa";
import { assignCustomer } from "./UserUtils";
export const PostBulkActionButtons = (props: any) => {
  const notify = useNotify();
  const [userCategories, setUserCategories] = useState<any>([]);
  const [mailTemplates, setMailTemplates] = useState<any>([]);

  const [bulkAction, setBulkAction] = useState("");
  const [changeEffects, setChangeEffects] = useState<any>([]);
  const [confirmBulkAction, setConfirmBulkAction] = useState(false);
  const [bulkDateField, setBulkDateField] = useState<any>(moment());
  const listContext = useListController();
  const [dateField, setDateField] = useState("");
  const [passwordField, setPasswordField] = useState("");
  const [bulkValueObj, setBulkValueObj] = useState<any>({});
  const [open, setOpen] = React.useState(false);
  const [statusDateRelates, setStatusDateRelates] = useState(false);
  const [categoryDateRelates, setCategoryDateRelates] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const clearBulkData = () => {
    setBulkAction("");
    setChangeEffects([]);
    setConfirmBulkAction(false);
    setBulkDateField(moment());
    setDateField("");
    setBulkValueObj({});
    setStatusDateRelates(false);
    setCategoryDateRelates(false);
    setPasswordField("");
  };
  const accountStatus: any = [
    { id: "ACTIVE", name: "Ativo" },
    { id: "CANCELED", name: "Cancelado" },
    { id: "NO_CONTACT", name: "Sem contato" },
    { id: "LICENSED", name: "Licenciado" },
    { id: "DECEASED", name: "Falecido" },
    { id: "UNKNOWN", name: "Desconhecido" },
  ];

  const ExecuteButton = (props: any) => {
    const { label, data } = props;

    // console.log("test exec btn", { label, data });
    return (
      <BulkUpdateButton
        variant="outlined"
        label={label}
        data={data}
        onClick={() => {
          handleClose();
          clearBulkData();
        }}
        icon={<FaCog />}
      />
    );
  };
  const fetchUserCategories = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/user-categories/crud`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    setUserCategories(data);
    // console.log({ fetchUserCategories: data });

    return data;
  };
  const fetchMailTemplates = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/mail-templates/crud`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const responseData = await response.json();

    if (!response.ok) {
      // console.log("response not OK!", responseData, response);
      const errorMessage = responseData.message || response.statusText;
      // notify(errorMessage, { type: "error" });
      notify("Erro ao carregar templates de e-mail", { type: "error" });
    }
    setMailTemplates(responseData);
    // console.log({ fetchUserCategories: responseData });

    return responseData;
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <BulkDeleteButton /> */}
      {/* <HasRegistrationCard /> */}
      <FormControl size="small">
        <Button
          size="small"
          variant="contained"
          startIcon={<ManageSearchIcon />}
          onClick={() => {
            handleOpen();
            fetchUserCategories();
            fetchMailTemplates();
          }}
        >
          Iniciar operações em massa
        </Button>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "85vw",
                  padding: 5,
                  marginTop: "1%",
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={1}>
                    <Typography variant="h6">Operações em Massa</Typography>
                  </Grid>

                  <Grid item xs={6} mb={1}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Selecione uma ação
                    </Typography>
                    <FormControl sx={{ m: 1, width: "100%" }}>
                      <InputLabel id="bulk-modal-action-helper-label">
                        Ação
                      </InputLabel>
                      <Select
                        labelId="bulk-modal-action-helper-label"
                        id="bulk-modal-action-helper"
                        value={bulkAction}
                        onChange={(e) => {
                          clearBulkData();
                          setBulkAction(e.target.value as string);

                          if (e.target.value === "bulkActionDelete") {
                            setChangeEffects([
                              "As contas serão removidas permanentemente.",
                            ]);
                          }
                          if (e.target.value === "bulkActionIUGU") {
                            setChangeEffects([
                              "As contas selecionadas serão criadas e vinculadas com cliente na IUGU. (Funcionará apenas em contas com e-mail e CPF, bem como apenas para contas não existentes.)",
                            ]);
                          }
                          if (e.target.value === "bulkActionMail") {
                            setChangeEffects([
                              "As contas selecionadas receberão um e-mail conforme template selecionado.",
                            ]);
                          }
                          if (
                            e.target.value ===
                            "bulkActionAssignRegistrationNumber"
                          ) {
                            setChangeEffects([
                              "Será gerado um número de matrícula para todas as contas selecionadas.",
                            ]);
                          }
                        }}
                        sx={{ width: "100%" }}
                      >
                        <MenuItem value={"bulkActionAssignRegistrationNumber"}>
                          Designar Matrícula(s)
                        </MenuItem>
                        <MenuItem value={"bulkActionDelete"}>
                          Remover contas
                        </MenuItem>
                        <MenuItem value={"bulkActionMail"}>
                          Enviar E-mail
                        </MenuItem>
                        <MenuItem value={"bulkActionHasCard"}>
                          Alterar carteirinha
                        </MenuItem>
                        <MenuItem value={"bulkActionDate"}>
                          Alterar campos de data
                        </MenuItem>
                        <MenuItem value={"bulkActionIUGU"}>
                          Criar e Vincular com IUGU
                        </MenuItem>
                        <Divider />
                        <MenuItem value={"bulkActionCategory"}>
                          Alteração de Categoria
                        </MenuItem>
                        <MenuItem value={"bulkActionStatus"}>
                          Alteração de Situação
                        </MenuItem>
                        <MenuItem value={"bulkActionPassword"}>
                          Alteração de Senha
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        Selecione um tipo de ação.
                      </FormHelperText>
                    </FormControl>
                    {bulkAction === "bulkActionHasCard" && (
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="bulk-modal-action-helper-label">
                          Possui Carteirinha?
                        </InputLabel>
                        <Select
                          value={bulkValueObj.hasPhysicalCard}
                          onChange={(e) => {
                            setBulkValueObj({
                              hasPhysicalCard:
                                e.target.value === "true" ? true : false,
                            });
                            setChangeEffects([
                              `As contas selecionadas terão a carteirinha alterada para [${e.target.value}]`,
                            ]);
                          }}
                          sx={{ width: "100%" }}
                        >
                          <MenuItem value={"true"}>Sim</MenuItem>
                          <MenuItem value={"false"}>Não</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {bulkAction === "bulkActionMail" && (
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        {/* {JSON.stringify(bulkValueObj)} */}
                        <InputLabel id="bulk-modal-action-helper-label">
                          Template de E-mail
                        </InputLabel>
                        <Select
                          label="Template"
                          value={bulkValueObj.templateId}
                          onChange={(e) => {
                            setBulkValueObj({
                              templateId: e.target.value,
                            });
                            // setChangeEffects([
                            //   `Todas as contas selecionadas serão alteradas para categoria [${
                            //     userCategories.find(
                            //       (category: any) =>
                            //         category.id === e.target.value
                            //     ).name
                            //   }]`,
                            // ]);
                            // setCategoryDateRelates(false);
                          }}
                          sx={{ width: "100%" }}
                        >
                          {mailTemplates.length > 0 &&
                            mailTemplates.map((mail: any) => (
                              <MenuItem value={mail.id}>
                                {mail.subject}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          Selecione um template para enviar para as contas
                          selecionadas.
                        </FormHelperText>
                      </FormControl>
                    )}
                    {bulkAction === "bulkActionCategory" && (
                      <>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <InputLabel id="bulk-modal-action-helper-label">
                            Categoria de Membro
                          </InputLabel>
                          <Select
                            label="Categoria"
                            value={bulkValueObj.userCategoryId}
                            onChange={(e) => {
                              setBulkValueObj({
                                userCategoryId: e.target.value,
                              });
                              setChangeEffects([
                                `Todas as contas selecionadas serão alteradas para categoria [${
                                  userCategories.find(
                                    (category: any) =>
                                      category.id === e.target.value
                                  ).name
                                }]`,
                              ]);
                              setCategoryDateRelates(false);
                            }}
                            sx={{ width: "100%" }}
                          >
                            {userCategories.length > 0 &&
                              userCategories.map((category: any) => (
                                <MenuItem value={category.id}>
                                  {category.name}
                                </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText>
                            Selecione uma categoria para alterar.
                          </FormHelperText>
                        </FormControl>
                        <FormControl size="small">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={categoryDateRelates}
                                onChange={(e) => {
                                  if (bulkValueObj.userCategoryId) {
                                    setCategoryDateRelates(e.target.checked);

                                    const categoryName = userCategories.find(
                                      (category: any) =>
                                        category.id ===
                                        bulkValueObj.userCategoryId
                                    ).name;

                                    const propertyName =
                                      categoryName === "COLETIVO"
                                        ? "collectiveDate"
                                        : categoryName === "REMIDO"
                                        ? "remissionDate"
                                        : categoryName === "ASPIRANTE"
                                        ? "aspirantDate"
                                        : categoryName === "COLETIVO REMIDO"
                                        ? "collectiveRemissionDate"
                                        : categoryName === "CONTRIBUINTE"
                                        ? "contributionDate"
                                        : categoryName === "EFETIVO"
                                        ? "effectiveDate"
                                        : categoryName === "SÊNIOR"
                                        ? "seniorDate"
                                        : "";

                                    if (e.target.checked) {
                                      setBulkValueObj({
                                        ...bulkValueObj,
                                        ...(propertyName && {
                                          [propertyName]: moment()
                                            .utc()
                                            .format(),
                                        }),
                                      });
                                      setChangeEffects([
                                        ...changeEffects,
                                        `Todas contas selecionadas terão a data de [${propertyName}] alterada para agora/hoje`,
                                      ]);
                                    } else {
                                      if (bulkValueObj.userCategoryId) {
                                        setBulkValueObj({
                                          userCategoryId:
                                            bulkValueObj.userCategoryId,
                                        });

                                        setChangeEffects([
                                          `Todas as contas selecionadas serão alteradas para categoria [${
                                            userCategories.find(
                                              (category: any) =>
                                                category.id ===
                                                bulkValueObj.userCategoryId
                                            ).name
                                          }]`,
                                        ]);
                                      }
                                    }
                                  }
                                }}
                              />
                            }
                            label={`Atualizar a data de ${
                              userCategories &&
                              bulkValueObj.userCategoryId &&
                              userCategories.find(
                                (category: any) =>
                                  category.id === bulkValueObj.userCategoryId
                              ).name
                            } para agora/hoje?`}
                          />
                        </FormControl>
                      </>
                    )}
                    {bulkAction === "bulkActionPassword" && (
                      <>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <InputLabel id="bulk-modal-action-helper-label">
                            Alterar senha?
                          </InputLabel>
                          <TextField
                            name={"password"}
                            value={passwordField}
                            onChange={(e) => {
                              setPasswordField(e.target.value);
                              setBulkValueObj({
                                password: e.target.value,
                              });
                              setChangeEffects([
                                `Todas contas selecionadas terão a senha alterada para [${e.target.value}]`,
                              ]);
                            }}
                            type="password"
                          />
                        </FormControl>
                      </>
                    )}
                    {bulkAction === "bulkActionStatus" && (
                      <>
                        <FormControl sx={{ m: 1, width: "100%" }} size="small">
                          <InputLabel id="bulk-modal-action-helper-label">
                            Situação Membro
                          </InputLabel>
                          <Select
                            label="Situação"
                            value={bulkValueObj.accountStatus}
                            onChange={(e) => {
                              setBulkValueObj({
                                accountStatus: e.target.value,
                              });
                              setChangeEffects([
                                `Todas contas selecionadas terão a situação alterada para [${
                                  accountStatus.find(
                                    (status: any) =>
                                      status.id === e.target.value
                                  ).name
                                }]`,
                              ]);
                              setStatusDateRelates(false);
                            }}
                          >
                            {accountStatus.map((status: any) => (
                              <MenuItem value={status.id}>
                                {status.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            Selecione uma categoria para alterar.
                          </FormHelperText>
                        </FormControl>
                        {(bulkValueObj.accountStatus === "CANCELED" ||
                          bulkValueObj.accountStatus === "LICENSED") && (
                          <FormControl size="small">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={statusDateRelates}
                                  onChange={(e) => {
                                    setStatusDateRelates(e.target.checked);
                                    const propertyName =
                                      bulkValueObj.accountStatus === "CANCELED"
                                        ? "cancellationDate"
                                        : bulkValueObj.accountStatus ===
                                          "LICENSED"
                                        ? "licenseDate"
                                        : "";
                                    if (e.target.checked) {
                                      setBulkValueObj({
                                        ...bulkValueObj,
                                        ...(propertyName && {
                                          [propertyName]: moment()
                                            .utc()
                                            .format(),
                                        }),
                                      });
                                      setChangeEffects([
                                        ...changeEffects,
                                        `Todas contas selecionadas terão a data de [${propertyName}] alterada para agora/hoje`,
                                      ]);
                                    } else {
                                      if (bulkValueObj.accountStatus) {
                                        setBulkValueObj({
                                          accountStatus:
                                            bulkValueObj.accountStatus,
                                        });

                                        setChangeEffects([
                                          `Todas contas selecionadas terão a situação alterada para [${
                                            accountStatus.find(
                                              (status: any) =>
                                                status.id ===
                                                bulkValueObj.accountStatus
                                            ).name
                                          }]`,
                                        ]);
                                      }
                                    }
                                  }}
                                />
                              }
                              label={`Atualizar a data de ${bulkValueObj.accountStatus} para agora/hoje?`}
                            />
                          </FormControl>
                        )}
                      </>
                    )}
                    {bulkAction === "bulkActionDate" && (
                      <>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <InputLabel id="bulk-modal-action-helper-label">
                            Campo de Data
                          </InputLabel>
                          <Select
                            value={dateField}
                            label="Campo de Data"
                            onChange={(e) => {
                              const now = moment();
                              if (e.target.value === "") {
                                setBulkValueObj({});
                                setChangeEffects([]);
                              } else {
                                setBulkDateField(now);
                                setBulkValueObj({
                                  [e.target.value]: now.utc().format(),
                                });
                                setChangeEffects([
                                  `Todas contas selecionadas terão o campo [${
                                    e.target.value
                                  }] alterado para [${now.utc().format()}]`,
                                ]);
                              }

                              setDateField(e.target.value as string);
                            }}
                            fullWidth
                          >
                            <MenuItem value={""}>Nenhum</MenuItem>
                            <MenuItem value={"createdAt"}>
                              Criação da conta
                            </MenuItem>
                            <MenuItem value={"updatedAt"}>
                              Atualização da conta
                            </MenuItem>
                            <MenuItem value={"effectiveDate"}>Efetivo</MenuItem>
                            <MenuItem value={"admissionDate"}>
                              Admissão
                            </MenuItem>
                            <MenuItem value={"licenseDate"}>
                              Licenciado
                            </MenuItem>
                            <MenuItem value={"collectiveDate"}>
                              Coletivo
                            </MenuItem>
                            <MenuItem value={"remissionDate"}>Remido</MenuItem>
                            <MenuItem value={"aspirantDate"}>
                              Aspirante
                            </MenuItem>
                            <MenuItem value={"cancellationDate"}>
                              Cancelado
                            </MenuItem>
                            <MenuItem value={"collectiveRemissionDate"}>
                              Coletivo Remido
                            </MenuItem>
                            <MenuItem value={"birthDate"}>Nascimento</MenuItem>
                            <MenuItem value={"contributionDate"}>
                              Contribuinte
                            </MenuItem>
                            <MenuItem value={"deceasedDate"}>
                              Falecimento
                            </MenuItem>
                            <MenuItem value={"seniorDate"}>Sênior</MenuItem>
                          </Select>
                          <FormHelperText>
                            Selecione um campo de data para alterar.
                          </FormHelperText>
                        </FormControl>
                        {dateField && (
                          <FormControl sx={{ m: 1, width: "100%" }}>
                            <LocalizationProvider
                              dateAdapter={AdapterMoment}
                              adapterLocale="pt-br"
                            >
                              <DateTimePicker
                                label="Especificar Data"
                                value={bulkDateField}
                                sx={{ width: "100%" }}
                                disabled={bulkValueObj.resetDate}
                                onChange={(v: any) => {
                                  setBulkDateField(v);
                                  setBulkValueObj({
                                    [dateField]: v.utc().format(),
                                  });
                                  setChangeEffects([
                                    `Todas contas selecionadas terão o campo [${dateField}] alterado para [${v
                                      .utc()
                                      .format()}]`,
                                  ]);
                                }}
                              />
                            </LocalizationProvider>
                            <FormHelperText>
                              Selecione uma data para alterar.
                            </FormHelperText>
                            {/** Checkbox (Reset) */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={bulkValueObj.resetDate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setBulkValueObj({
                                        [dateField]: null,
                                        resetDate: dateField,
                                      });
                                      setChangeEffects([
                                        `Todas contas selecionadas terão o campo [${dateField}] resetado.`,
                                      ]);
                                    } else {
                                      setBulkValueObj({
                                        [dateField]: bulkDateField
                                          .utc()
                                          .format(),
                                      });
                                      setChangeEffects([
                                        `Todas contas selecionadas terão o campo [${dateField}] alterado para [${bulkDateField
                                          .utc()
                                          .format()}]`,
                                      ]);
                                    }
                                    // setBulkValueObj({
                                    //   [dateField]: null,
                                    //   resetDate: e.target.checked,
                                    // });
                                    // setChangeEffects([
                                    //   `Todas contas selecionadas terão o campo [${dateField}] resetado.`,
                                    // ]);
                                  }}
                                />
                              }
                              label={`Resetar campo [${dateField}]`}
                            />
                          </FormControl>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6} mb={1}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Contas afetadas
                    </Typography>
                    <MuiList dense>
                      {/* {listContext.selectedIds.map((id: any) => (
                            <ListItem>
                              <ListItemText primary={"- " + id} />
                            </ListItem>
                          ))} */}
                      {/**
                       * filter data by selectedIds
                       */}
                      {listContext.data &&
                        listContext.data.map((record: any, index) => {
                          if (listContext.selectedIds.includes(record.id)) {
                            return (
                              <ListItemButton key={index}>
                                <ListItemText
                                  //   primaryTypographyProps={{ fontSize: 10 }}
                                  primary={"- " + record.fullName}
                                />
                              </ListItemButton>
                            );
                          }
                        })}
                    </MuiList>
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Efeitos
                    </Typography>
                    <Box>
                      {changeEffects.map((effect: any) => (
                        <Alert severity="warning">{effect}</Alert>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: "#ccc",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      Inspect:
                      <br /> {JSON.stringify(bulkValueObj)}
                    </Box>
                  </Grid>
                </Grid>
                {bulkAction && (
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={confirmBulkAction}
                        onChange={(e) => setConfirmBulkAction(e.target.checked)}
                      />
                    }
                    label="Confirma esta ação?"
                  />
                )}
                <br />
                <Divider />
                {confirmBulkAction &&
                  open &&
                  bulkAction !== "bulkActionDelete" &&
                  bulkAction !== "bulkActionIUGU" &&
                  bulkAction !== "bulkActionAssignRegistrationNumber" &&
                  bulkAction !== "bulkActionMail" && (
                    <ExecuteButton label="Executar" data={bulkValueObj} />
                  )}
                {confirmBulkAction && bulkAction === "bulkActionDelete" && (
                  <BulkDeleteButton />
                )}
                {confirmBulkAction && bulkAction === "bulkActionIUGU" && (
                  <Button
                    size="small"
                    startIcon={<FaCog />}
                    variant="outlined"
                    onClick={async () => {
                      // console.log("customExecc", bulkValueObj);
                      // loop list context and create iugu accounts

                      const auth = localStorage.getItem("auth");
                      const authJson = auth ? JSON.parse(auth) : auth;

                      for (const record of listContext.data) {
                        if (listContext.selectedIds.includes(record.id)) {
                          if (record.email && record.cpf && record.fullName) {
                            const data = {
                              email: record.email,
                              name: record.fullName,
                              cpf_cnpj: record.cpf,
                            };
                            try {
                              const findExistingCustomer = await fetch(
                                `${process.env.REACT_APP_API_PATH}/iugu/customers/${record.email}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                      "Bearer " + authJson.access_token,
                                  },
                                }
                              );
                              const existingCustomer =
                                await findExistingCustomer.json();

                              if (existingCustomer.id) {
                                // console.log(
                                //   "existingCustomer",
                                //   existingCustomer
                                // );
                                await assignCustomer(
                                  existingCustomer.id,
                                  record.id,
                                  false
                                );
                                notify("Conta existente: " + record.email, {
                                  type: "info",
                                });
                              } else {
                                // console.log("new customer add");
                                const response = await fetch(
                                  `${process.env.REACT_APP_API_PATH}/iugu/customers`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization:
                                        "Bearer " + authJson.access_token,
                                    },
                                    body: JSON.stringify(data),
                                  }
                                );

                                const dataResponse = await response.json();
                                if (dataResponse.id && record.id) {
                                  await assignCustomer(
                                    dataResponse.id,
                                    record.id,
                                    false
                                  );
                                  notify("Cadastro criado: " + record.email, {
                                    type: "success",
                                  });
                                  handleClose();
                                }
                              }
                            } catch (error) {
                              console.log("error", error);
                              notify(
                                "Operação falhou. Registro: " + record.email,
                                {
                                  type: "error",
                                }
                              );
                            }
                          }
                        }
                      }
                      notify("Operação concluída.", {
                        type: "info",
                      });
                      handleClose();
                    }}
                  >
                    Executar
                  </Button>
                )}
                {confirmBulkAction && bulkAction === "bulkActionMail" && (
                  <Button
                    size="small"
                    startIcon={<FaCog />}
                    variant="outlined"
                    onClick={async () => {
                      // console.log("customExec", bulkValueObj);
                      // loop list context and create iugu accounts

                      const auth = localStorage.getItem("auth");
                      const authJson = auth ? JSON.parse(auth) : auth;

                      for (const record of listContext.data) {
                        if (listContext.selectedIds.includes(record.id)) {
                          if (record.email) {
                            try {
                              const response = await fetch(
                                `${process.env.REACT_APP_API_PATH}/users/mail/send/${record.id}/${bulkValueObj.templateId}`,
                                {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                      "Bearer " + authJson.access_token,
                                  },
                                }
                              );

                              const dataResponse = await response.json();
                              if (!response.ok) {
                                // console.log(
                                //   "response not OK!",
                                //   dataResponse,
                                //   response
                                // );
                                const errorMessage =
                                  dataResponse.message || response.statusText;

                                notify(
                                  `Erro ao enviar e-mail para: ${record.email}`,
                                  {
                                    type: "error",
                                  }
                                );
                                // console.log("error", errorMessage);

                                // wait 2 sec
                                await new Promise((resolve) =>
                                  setTimeout(resolve, 2000)
                                );
                              }
                              if (dataResponse.message && dataResponse.type) {
                                notify(dataResponse.message, {
                                  type: dataResponse.type,
                                });
                                // wait 2 sec
                                await new Promise((resolve) =>
                                  setTimeout(resolve, 2000)
                                );
                              }
                              handleClose();
                            } catch (error) {
                              // console.log("error", error);
                              notify(
                                "Operação falhou. Registro: " + record.email,
                                {
                                  type: "error",
                                }
                              );
                            }
                          }
                        }
                      }
                      notify("Operação concluída.", {
                        type: "info",
                      });
                      handleClose();
                    }}
                  >
                    Executar
                  </Button>
                )}
                {confirmBulkAction &&
                  bulkAction === "bulkActionAssignRegistrationNumber" && (
                    <Button
                      size="small"
                      startIcon={<FaCog />}
                      variant="outlined"
                      onClick={async () => {
                        // console.log("customExec", bulkValueObj);
                        // loop list context and create iugu accounts

                        const auth = localStorage.getItem("auth");
                        const authJson = auth ? JSON.parse(auth) : auth;

                        for (const record of listContext.data) {
                          if (listContext.selectedIds.includes(record.id)) {
                            if (record.email) {
                              try {
                                const response = await fetch(
                                  `${process.env.REACT_APP_API_PATH}/users/assign-registration-number/${record.id}`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization:
                                        "Bearer " + authJson.access_token,
                                    },
                                  }
                                );

                                const dataResponse = await response.json();
                                if (
                                  response.status < 200 ||
                                  response.status > 299
                                ) {
                                  // console.log("error", dataResponse.message);
                                  notify(
                                    "Operação falhou. Registro: " +
                                      record.email +
                                      " - " +
                                      dataResponse.message,
                                    {
                                      type: "error",
                                    }
                                  );
                                  await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                  );
                                  // handleClose();
                                  // exit
                                  return;
                                }
                                if (dataResponse.message && dataResponse.type) {
                                  notify(dataResponse.message, {
                                    type: dataResponse.type,
                                  });
                                  // wait 2 sec
                                  await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                  );
                                }
                                notify("Operação concluída.", {
                                  type: "info",
                                });
                                handleClose();
                              } catch (error) {
                                console.log("error", error);
                                notify(
                                  "Operação falhou. Registro: " + record.email,
                                  {
                                    type: "error",
                                  }
                                );
                              }
                            }
                          }
                        }
                      }}
                    >
                      Executar
                    </Button>
                  )}
                {/* <Button
                      size="small"
                      variant="contained"
                      startIcon={<ManageSearchIcon />}
                    >
                      Executar
                    </Button> */}
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                    clearBulkData();
                  }}
                >
                  Cancelar
                </Button>
              </Card>
            </Box>
          </Fade>
        </Modal>
      </FormControl>
    </div>
  );
};
