import {
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  FunctionField,
  Datagrid,
  useEditController,
  Labeled,
  ArrayField,
  SingleFieldList,
  ChipField,
  ShowContextProvider,
  useRedirect,
  RichTextField,
} from "react-admin";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { HiEye } from "react-icons/hi";
import Typography from "@mui/material/Typography";
import { AppError } from "../../AppError";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { AppLoading } from "../../components/AppLoading";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  assignCustomer,
  determineUserReadableRole,
  truncateString,
} from "./UserUtils";
import { formatCNPJ, formatCPF } from "./CPFUtils";
import { statusLabels } from "../../components/pages/iugu/iugu-utils";

export const UserShow = (props: any) => {
  const redirect = useRedirect();
  const urlParams = useParams();
  const urlId = urlParams.id;

  const userRole = localStorage.getItem("roles");
  const auth = localStorage.getItem("auth");
  const authJson = auth ? JSON.parse(auth) : auth;
  const uid = authJson.id;
  if (auth && userRole) {
    // console.log("userId", uid);
    if (uid !== urlId && determineUserReadableRole(userRole) === "user") {
      redirect("/users/crud/" + uid + "/show");
    }
  } else {
    // redirect home
    redirect("/");
  }

  // console.log("props", props);
  // console.log("userAuth", userAuth);
  const [iuguSubscriptions, setIuguSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsSet, setTransactionsSet] = useState(false);
  const [foundCustomers, setFoundCustomers] = useState(true);
  const [assignMessage, setAssignMessage] = useState("");
  const [possibleClients, setPossibleClients] = useState([]);
  const [createdClient, setCreatedClient] = useState({});
  const [iuguLoading, setIuguLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [appError, setAppError] = useState("");
  const autoLoadClient = localStorage.getItem("autoLoadClient");
  const roles = localStorage.getItem("roles");
  // console.log(useParams());

  const controller: any = useEditController({ resource: "users/crud", urlId });

  const createClient = async (data: any, id?: string) => {
    if (!data.email || !data.name || !data.cpf_cnpj) {
      setIuguLoading(false);
      return setAppError(
        "Email/CPF são obrigatórios para criação de contas IUGU. Atualize os dados cadastrais deste sócio."
      );
    }

    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;

    // console.log("fetch create client", data);
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/customers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
        body: JSON.stringify(data),
      }
    );
    const dataResponse = await response.json();
    setIuguLoading(false);
    if (dataResponse.id) {
      setCreatedClient(dataResponse);
      localStorage.setItem("autoLoadClient", dataResponse.email);
      // console.log("will get clients", dataResponse.email);
      // await getClientsByEmail(dataResponse.email);
      if (id) await assignCustomer(dataResponse.id, id, true);
      // window.location.reload();
    }
    // console.log("fetch create client data", dataResponse);
    if (dataResponse.error) {
      setAssignMessage(dataResponse.error);
    }
  };

  const getClientsByEmail = async (query: string) => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    // console.log("fetch get client by id", id);
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/customers/${query}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    setIuguLoading(false);
    setPossibleClients(data);
    // console.log("fetch get clients by query data", data);
    if (data.error || data.length === 0) {
      if (data.length === 0) setFoundCustomers(false);
      setAppError(
        data.error ||
          "Nenhum cliente encontrado. Deseja Criar este cliente na Iugu? (clique no botão Criar abaixo)"
      );
    }
    if (data.length > 0) setFoundCustomers(true);
  };

  // The URL has the following format: http://localhost:3001/#/users/crud/1/show where id is 1.
  // Function to get and set transactions based on the user id
  const getTransactions = async (id: string) => {
    // console.log("fetch get transactions", id);
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    // if (!authJson) return;
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/transactions/crud?filter={"memberId":"${id}"}&range=[0,9]&sort=["sCompetencia","DESC"]`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log("fetch get transactions data", data, id);
    setTransactions(data);
    setTransactionsSet(true);
  };

  // Get the user id from the URL
  const url = window.location.href;
  const id = url.split("/")[6];
  // console.log("id", id);

  // Call the function to get the transactions
  if (!transactionsSet) getTransactions(id);

  const filters = useMemo(() => {
    if (transactions) {
      return {
        // mailName: searchText, // Use the 'q' filter for searching by email or name
      };
    }
    if (transactions) {
      const result = transactions;
      // console.log("RESULT", result);
      return result;
    }
    return {};
  }, [transactions]);
  const { record } = props;

  const fetchUserIuguSubscriptions = async (id: string) => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const start = (page - 1) * perPage;
    const cidStr = `&customer_id=${id}`;
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/subscriptions?s=${start}&l=${perPage}${cidStr}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ fetchUserIuguSubscriptions: data });
    setIuguSubscriptions(data.items);
  };
  // console.log("record", props);
  useEffect(() => {
    if (
      determineUserReadableRole(roles) === "admin" &&
      controller &&
      controller.record &&
      controller.record.iuguId
    ) {
      // console.log("mounting", controller.record.iuguId);
      fetchUserIuguSubscriptions(controller.record.iuguId);
    }
  }, [controller.record]);
  // useEffect(() => {
  //   if (createdClient && localStorage.getItem("autoLoadClient")) {
  //     getClientsByEmail(localStorage.getItem("autoLoadClient") || "");
  //   }
  // }, [createdClient]);

  return (
    <ShowContextProvider value={controller}>
      <SimpleShowLayout className="RaShowClass">
        <PageTopBreadcrumb
          title="Sócios"
          firstPath="/#/users/crud"
          secondTitle="Visualizando"
          thirdTitle={
            controller && controller.record && controller.record.fullName
          }
          actionType="edit"
        />
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Card sx={{ padding: 2, marginBottom: 3 }}>
              <CardHeader title={"Informações Pessoais"} />
              <CardContent>
                <Grid container spacing={0.5}>
                  <Grid item sm={6} xs={12}>
                    <Stack>
                      <FunctionField
                        render={(record: any) =>
                          record.fullName ? (
                            <Labeled>
                              <TextField source="fullName" label="Nome" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.email ? (
                            <Labeled>
                              <TextField source="email" label="Email" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      {/* <FunctionField
                        render={(record: any) =>
                          record.login ? (
                            <Labeled>
                              <TextField source="login" label="Login" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      /> */}
                      {/* <FunctionField
                        render={(record: any) =>
                          record.cpf ? (
                            <Labeled>
                              <TextField source="cpf" label="CPF" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      /> */}
                      <Labeled>
                        <BooleanField
                          source="hasPhysicalCard"
                          label="Possui Carteirinha"
                        />
                      </Labeled>
                      <Labeled>
                        <BooleanField source="userEnabled" label="ADIMPLENTE" />
                      </Labeled>
                      {/* <FunctionField
                        render={(record: any) =>
                          record.registrationNumber ? (
                            <Labeled>
                              <TextField
                                source="registrationNumber"
                                label="Matrícula"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      /> */}
                      <FunctionField
                        render={(record: any) =>
                          record.registrationNumberProv ? (
                            <Labeled>
                              <TextField
                                source="registrationNumberProv"
                                label="Matrícula (Provisória)"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.birthDate ? (
                            <Labeled>
                              <DateField
                                source="birthDate"
                                locales="pt-BR"
                                label="Data de Nascimento"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.createdAt ? (
                            <Labeled>
                              <DateField
                                source="createdAt"
                                locales="pt-BR"
                                label="Criação da conta"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.gender ? (
                            <Labeled>
                              <FunctionField
                                label="Sexo"
                                render={(record: any) => (
                                  <>
                                    {record.gender === "F"
                                      ? "Feminino"
                                      : record.gender === "M"
                                      ? "Masculino"
                                      : "N/A"}
                                  </>
                                )}
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.maritalStatus ? (
                            <Labeled>
                              <FunctionField
                                label="Estado Civil"
                                render={(record: any) => (
                                  <>
                                    {record.maritalStatus === "SOLTEIRO"
                                      ? "Solteiro(a)"
                                      : record.maritalStatus === "CASADO"
                                      ? "Casado(a)"
                                      : record.maritalStatus === "DIVORCIADO"
                                      ? "Divorciado(a)"
                                      : record.maritalStatus === "VIUVO"
                                      ? "Viúvo(a)"
                                      : record.maritalStatus === "UNIAO_ESTAVEL"
                                      ? "União Estável"
                                      : record.maritalStatus === "DESQUITADO"
                                      ? "Desquitado(a)"
                                      : "N/A"}
                                  </>
                                )}
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.phoneNumber ? (
                            <Labeled>
                              <TextField source="phoneNumber" label="Celular" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.residencePhoneNumber ? (
                            <Labeled>
                              <TextField
                                source="residencePhoneNumber"
                                label="Telefone Residencial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      {determineUserReadableRole(roles) === "admin" && (
                        <Labeled>
                          <ReferenceField
                            source="roleId"
                            reference="user-roles/crud"
                            link="show"
                            label="Regra de Acesso"
                          >
                            <TextField source="name" />
                          </ReferenceField>
                        </Labeled>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Stack>
                      <FunctionField
                        render={(record: any) =>
                          record.CREA ? (
                            <Labeled>
                              <TextField source="CREA" label="CREA" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.professionalActivity ? (
                            <Labeled>
                              <TextField
                                source="professionalActivity"
                                label="Atividade Profissional Principal"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.accountStatus ? (
                            <Labeled>
                              <FunctionField
                                label="Situação da Conta"
                                render={(record: any) => (
                                  <>
                                    {record.accountStatus === "ACTIVE"
                                      ? "Ativa"
                                      : record.accountStatus === "DECEASED"
                                      ? "Falecido"
                                      : record.accountStatus === "CANCELED"
                                      ? "Cancelado"
                                      : record.accountStatus === "LICENSED"
                                      ? "Licenciado"
                                      : record.accountStatus === "PENDING"
                                      ? "Pendente"
                                      : record.accountStatus === "NO_CONTACT"
                                      ? "Sem Contato"
                                      : record.accountStatus === "UNKNOWN"
                                      ? "Desconhecido"
                                      : "N/A"}
                                  </>
                                )}
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <Labeled>
                        <ReferenceField
                          source="userCategoryId"
                          reference="user-categories/crud"
                          link="show"
                          label="Categoria do Sócio"
                        >
                          <TextField source="name" />
                        </ReferenceField>
                      </Labeled>
                      <FunctionField
                        render={(record: any) =>
                          record.address ? (
                            <Labeled>
                              <TextField source="address" label="Endereço" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.addressNeighborhood ? (
                            <Labeled>
                              <TextField
                                source="addressNeighborhood"
                                label="Bairro"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.addressState ? (
                            <Labeled>
                              <TextField source="addressState" label="Estado" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.addressCity ? (
                            <Labeled>
                              <TextField source="addressCity" label="Cidade" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.addressZipCode ? (
                            <Labeled>
                              <TextField source="addressZipCode" label="CEP" />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.addressRegion ? (
                            <Labeled>
                              <TextField
                                source="addressRegion"
                                label="Região"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>

                {/* <FunctionField
                  label="Full Name"
                  render={(record: any) => (
                    <div className="flex flex-col space-y-2">
                      <div className="flex flex-col">
                        <b>{`${record.fullName}`}</b>
                      </div>
                      <div className="flex flex-col">
                        <Label className="mb-1">Matricula:</Label>
                        <b>{record.registrationNumber}</b>
                      </div>
                      <div className="flex flex-col">
                        <Label className="mb-1">Emails:</Label>
                        <b>
                          {record.email || "N/A"},{" "}
                          {record.commercialEmail || "N/A"}
                        </b>
                      </div>
                      <div className="flex flex-col">
                        <Label className="mb-1">Data de admissão:</Label>
                        <b>
                          <DateField source="admissionDate" locales={"pt-BR"} />
                        </b>
                      </div>
                    </div>
                  )}
                /> */}
              </CardContent>
            </Card>
            {controller.record && controller.record.dependents.length > 0 && (
              <Card sx={{ padding: 2, marginBottom: 3 }}>
                <CardHeader title="Dependentes" />
                <CardContent>
                  <Grid item sm={12} xs={12}>
                    <FunctionField
                      render={(record: any) => (
                        <TableContainer component={Paper}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Dependentes</TableCell>
                                <TableCell align="right">Nascimento</TableCell>
                                <TableCell align="right">Parentesco</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {record.dependents &&
                                record.dependents.map((item: any, i: any) => {
                                  return (
                                    <TableRow
                                      key={i}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {item.name}
                                      </TableCell>
                                      <TableCell align="right">
                                        {item.birthDate}
                                      </TableCell>
                                      <TableCell align="right">
                                        {item.kinship}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    />
                  </Grid>
                </CardContent>
              </Card>
            )}

            {controller &&
              controller.record &&
              controller.record.userGraduations.length > 0 && (
                <Card sx={{ padding: 2, marginBottom: 3 }}>
                  <CardHeader title="Formações" />

                  <CardContent>
                    <Grid container spacing={0.5}>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        sx={
                          {
                            // overflowX: "scroll",
                          }
                        }
                      >
                        <ArrayField label="Formações" source="userGraduations">
                          <Paper>
                            <Datagrid
                              bulkActionButtons={false}
                              size="small"
                              optimized
                              // sx={{
                              //   "& .MuiTableCell-root": {
                              //     padding: 8,
                              //   },
                              // }}
                            >
                              {/* <ChipField source="graduation.name" size="small" /> */}
                              <TextField
                                source="graduation.name"
                                label="Graduação"
                              />
                              <TextField
                                source="educationalInstitution.name"
                                label="Instituição de Ensino"
                              />
                              <TextField
                                source="yearCompletion"
                                label="Ano de Conclusão"
                              />
                            </Datagrid>
                          </Paper>
                        </ArrayField>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            {determineUserReadableRole(roles) === "admin" && (
              <Card sx={{ padding: 2, marginBottom: 3 }}>
                <FunctionField
                  render={(record: any) => (
                    <CardHeader
                      title="Integração IUGU"
                      subheader={
                        iuguLoading ? (
                          <AppLoading size={20} showText={true} />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                />

                <CardContent>
                  {appError && <AppError message={appError} title="Erro" />}
                  <FunctionField
                    label="Iugu ID"
                    render={(record: any) => {
                      if (record.iuguId) {
                        return (
                          <>
                            <p>
                              <b>Conta Iugu Vinculada: {record.iuguId}</b>
                            </p>
                            <br />
                            <ButtonGroup fullWidth>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  // console.log("click", record);
                                  // getClientsByEmail(record.email);
                                  window.location.href =
                                    "/#/iugu-invoices/create?uid=" + id;
                                }}
                              >
                                Criar Fatura
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  // console.log("click", record);
                                  // getClientsByEmail(record.email);
                                  // window.location.href = "/#/transactions/crud";
                                  window.location.href =
                                    "/#/subscriptions/create?uid=" + id;
                                }}
                              >
                                Criar Assinatura
                              </Button>
                            </ButtonGroup>
                            <br />
                            <Divider />
                            <br />

                            <Typography variant="h6">
                              Assinaturas do Sócio
                            </Typography>

                            {iuguSubscriptions.length > 0 && (
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Plano</TableCell>
                                    <TableCell>Faturas</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {iuguSubscriptions.map(
                                    (subscription: any) => (
                                      <TableRow key={subscription.id}>
                                        <TableCell>
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            title={subscription.id}
                                            onClick={() => {
                                              // window.location.href =
                                              //   "/#/invoices/crud/" + invoice.id + "/show";
                                            }}
                                          >
                                            {truncateString(
                                              subscription.id,
                                              13
                                            )}
                                          </Button>
                                          <br />
                                          <Typography variant="caption">
                                            <b>Plano: </b>
                                            {subscription.plan_name ||
                                              "Desconhecido"}
                                            {/* <br />
                                        <b>Situação da Assinatura: </b>
                                        {subscription.active ? "Ativa" : "Não ativa"} */}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          {subscription.recent_invoices.map(
                                            (item: any) => (
                                              <>
                                                <Table size="small">
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell>
                                                        Cobrança
                                                      </TableCell>
                                                      <TableCell>
                                                        Vencimento
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    <TableRow key={item.id}>
                                                      <TableCell>
                                                        <Typography variant="caption">
                                                          {truncateString(
                                                            item.id || "",
                                                            13
                                                          )}
                                                          <br />
                                                          <b>
                                                            Status:{" "}
                                                            {statusLabels[
                                                              item.status
                                                            ] || item.status}
                                                          </b>
                                                        </Typography>
                                                      </TableCell>
                                                      <TableCell>
                                                        {moment(
                                                          item.due_date
                                                        ).format("DD-MM-YYYY")}
                                                      </TableCell>
                                                      <TableCell></TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </Table>
                                                <Divider
                                                  sx={{ marginTop: 1 }}
                                                />
                                              </>
                                            )
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            )}
                            <FunctionField
                              render={(record: any) => (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={() => {
                                    // console.log("click", record);
                                    // getClientsByEmail(record.email);
                                    // window.location.href = "/#/transactions/crud";
                                    window.location.href =
                                      "/#/subscriptions?customer_id=" +
                                      record.iuguId;
                                  }}
                                >
                                  Ver Todas
                                </Button>
                              )}
                            />
                          </>
                        );
                      } else {
                        return (
                          <div className="">
                            <div
                              className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
                              role="alert"
                            >
                              Esta conta ainda não possui cliente vinculado na
                              Iugu, para emitir faturas ou criar assinatura,
                              faça a vinculação abaixo.
                            </div>
                            {assignMessage && (
                              <div
                                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                role="alert"
                              >
                                {assignMessage}
                              </div>
                            )}
                            <Button
                              variant="contained"
                              onClick={() => {
                                // console.log("click", record);
                                setIuguLoading(true);
                                getClientsByEmail(record.email);
                                // window.location.href = "/#/transactions/crud";
                              }}
                            >
                              Iniciar Vinculação
                            </Button>
                            {!foundCustomers && (
                              <Button
                                color="success"
                                variant="contained"
                                onClick={() => {
                                  setIuguLoading(true);

                                  const data = {
                                    email: record.email,
                                    name: record.fullName,
                                    cpf_cnpj: record.cpf,
                                    // state: record.addressState,
                                    // city: record.addressCity,
                                    // zip_code: record.addressZipCode,
                                    // district: record.addressNeighborhood,
                                    // street: record.address,
                                  };
                                  // console.log("data", data);
                                  createClient(data, record.id);
                                }}
                              >
                                Criar & Vincular
                              </Button>
                            )}
                            {possibleClients && (
                              <>
                                <TableContainer className="RaDatagrid-tableWrapper">
                                  <Table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <TableHead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                      <TableRow>
                                        <TableCell
                                          scope="col"
                                          className="px-6 py-3"
                                        >
                                          ID
                                        </TableCell>
                                        <TableCell
                                          scope="col"
                                          className="px-6 py-3"
                                        >
                                          Nome
                                        </TableCell>
                                        <TableCell
                                          scope="col"
                                          className="px-6 py-3"
                                        >
                                          Vincular
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {/* {JSON.stringify(possibleClients)} */}
                                      {possibleClients.map((client: any) => (
                                        <TableRow key={client.id}>
                                          <TableCell scope="row">
                                            <Typography fontSize={11}>
                                              {client.id}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography fontSize={11}>
                                              {client.name}
                                              <br />
                                              {formatCPF(client.cpf_cnpj)}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              variant="contained"
                                              size="small"
                                              color="primary"
                                              onClick={() => {
                                                assignCustomer(
                                                  client.id,
                                                  record.id
                                                );
                                              }}
                                            >
                                              Vincular
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            )}
                          </div>
                        );
                      }
                    }}
                  />
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <FunctionField
              render={(record: any) => (
                <Card sx={{ padding: 2, marginBottom: 3 }}>
                  <Grid container>
                    {/* Image */}
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      lg={2}
                      sx={{ padding: 2, borderWidth: 1 }}
                    >
                      <CardMedia
                        component="img"
                        image={
                          record.physicalCardPicture &&
                          record.physicalCardPicture.path
                            ? `${process.env.REACT_APP_UPLOAD_PATH}/` +
                              record.physicalCardPicture.path
                            : "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg"
                        }
                        alt={"name"}
                      />
                    </Grid>
                    {/* Data */}
                    <Grid item xs={12} sm={8} lg={10} sx={{ padding: 2 }}>
                      <CardContent>
                        <Typography variant="h5">{record.fullName}</Typography>
                        <Typography variant="body2">
                          CPF: {record.cpf}
                          <br />
                          {record.cnpj && (
                            <>
                              CNPJ: {formatCNPJ(record.cnpj)}
                              <br />
                            </>
                          )}
                        </Typography>
                        {record.registrationNumber && (
                          <Typography variant="body2">
                            Matrícula:{" "}
                            {record.registrationNumber +
                              "-" +
                              record.registrationNumberDigit}
                          </Typography>
                        )}
                        {record.birthDate && (
                          <Typography variant="body2">
                            Nascimento: {moment(record.birthDate).format("L")}
                          </Typography>
                        )}

                        {/* <Typography variant="body2">Cargos:</Typography> */}
                        {
                          /** IF is admin, show field 'observation' */
                          determineUserReadableRole(roles) === "admin" && (
                            <>
                              <hr />
                              <Typography variant="h6">Observações:</Typography>
                              <RichTextField
                                source="observation"
                                label="Observações"
                                // lineHeight={"0.3"}
                              />
                            </>
                          )
                        }
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              )}
            />
            {controller &&
              controller.record &&
              controller.record.positionRoles.length > 0 && (
                <Card sx={{ padding: 2, marginBottom: 3 }}>
                  <CardHeader title="Cargos e Funções" />

                  <CardContent>
                    <Grid container spacing={0.5}>
                      <Grid item xs={12}>
                        <FunctionField
                          render={(record: any) => (
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Cargos</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Período</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {record.positionRoles &&
                                    record.positionRoles.map(
                                      (item: any, i: any) => {
                                        return (
                                          <TableRow
                                            key={i}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {item.name}

                                              {item.roleType ===
                                              "COMISSAO_EXTERNA" ? (
                                                <>
                                                  <br />
                                                  {item.secondaryRole}
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              {item.technicalDivision &&
                                                item.technicalDivision.name && (
                                                  <>
                                                    <br />
                                                    Divisão Técnica:{" "}
                                                    {
                                                      item.technicalDivision
                                                        .name
                                                    }
                                                  </>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                              {/** if have roleStatus with string name, display */}
                                              {item.roleStatus &&
                                              item.roleStatus.name
                                                ? item.roleStatus.name
                                                : ""}
                                            </TableCell>
                                            <TableCell align="right">
                                              {item.roleType !==
                                              "COMISSAO_EXTERNA" ? (
                                                <>
                                                  Período: {item.yearRange}
                                                  <br />
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              {item.fromDate &&
                                                "De: " +
                                                  moment(item.fromDate).format(
                                                    "L"
                                                  )}
                                              {item.toDate &&
                                                " - Até: " +
                                                  moment(item.toDate).format(
                                                    "L"
                                                  )}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            {controller &&
              controller.record &&
              controller.record.userElections.length > 0 && (
                <Card sx={{ padding: 2, marginBottom: 3 }}>
                  <CardHeader title="Eleições" />

                  <CardContent>
                    <Grid container spacing={0.5}>
                      <Grid item xs={12}>
                        <FunctionField
                          render={(record: any) => (
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Eleições</TableCell>
                                    <TableCell align="right">
                                      Participou
                                    </TableCell>
                                    <TableCell align="right">Ano</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {record.userElections &&
                                    record.userElections.map(
                                      (item: any, i: any) => {
                                        return (
                                          <TableRow
                                            key={i}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {item.name}
                                            </TableCell>
                                            <TableCell align="right">
                                              {item.participated
                                                ? "Sim"
                                                : "Não"}
                                            </TableCell>
                                            <TableCell align="right">
                                              {item.year}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            <Card sx={{ padding: 2, marginBottom: 3 }}>
              <CardHeader title="Informações de Datas" />

              <CardContent>
                <Grid container spacing={0.5}>
                  <Grid item sm={6} xs={12}>
                    <Stack>
                      <FunctionField
                        render={(record: any) =>
                          record.birthDate ? (
                            <Labeled>
                              <DateField
                                source="birthDate"
                                locales="pt-BR"
                                label="Data de Nascimento"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.admissionDate ? (
                            <Labeled>
                              <DateField
                                source="admissionDate"
                                locales="pt-BR"
                                label="Data de Admissão"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.remissionDate ? (
                            <Labeled>
                              <DateField
                                source="remissionDate"
                                locales="pt-BR"
                                label="Data de Remido"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.aspirantDate ? (
                            <Labeled>
                              <DateField
                                source="aspirantDate"
                                locales="pt-BR"
                                label="Data de Aspirante"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.collectiveDate ? (
                            <Labeled>
                              <DateField
                                source="collectiveDate"
                                locales="pt-BR"
                                label="Data de Coletivo"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.seniorDate ? (
                            <Labeled>
                              <DateField
                                source="seniorDate"
                                locales="pt-BR"
                                label="Data de Sênior"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Stack>
                      <FunctionField
                        render={(record: any) =>
                          record.cancellationDate ? (
                            <Labeled>
                              <DateField
                                source="cancellationDate"
                                locales="pt-BR"
                                label="Data de Cancelamento"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.deceasedDate ? (
                            <Labeled>
                              <DateField
                                source="deceasedDate"
                                locales="pt-BR"
                                label="Data de Falecimento"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.licenseDate ? (
                            <Labeled>
                              <DateField
                                source="licenseDate"
                                locales="pt-BR"
                                label="Data de Licenciado"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.effectiveDate ? (
                            <Labeled>
                              <DateField
                                source="effectiveDate"
                                locales="pt-BR"
                                label="Data de Efetivo"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.collectiveRemissionDate ? (
                            <Labeled>
                              <DateField
                                source="collectiveRemissionDate"
                                locales="pt-BR"
                                label="Data de Remido Coletivo"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.contributionDate ? (
                            <Labeled>
                              <DateField
                                source="contributionDate"
                                locales="pt-BR"
                                label="Data Contribuinte"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ padding: 2, marginBottom: 3 }}>
              <FunctionField
                render={(record: any) => (
                  <CardHeader
                    title="Informações Comerciais"
                    // subheader={
                    //   "CPF: " +
                    //   record.cpf +
                    //   " - " +
                    //   "Matrícula: " +
                    //   record.registrationNumber
                    // }
                  />
                )}
              />

              <CardContent>
                <Grid container spacing={0.5}>
                  <Grid item sm={6} xs={12}>
                    <Stack>
                      <FunctionField
                        render={(record: any) =>
                          record.commercialEmail ? (
                            <Labeled>
                              <TextField
                                source="commercialEmail"
                                label="Email Comercial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.commercialAddress ? (
                            <Labeled>
                              <TextField
                                source="commercialAddress"
                                label="Endereço Comercial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.commercialAddressNeighborhood ? (
                            <Labeled>
                              <TextField
                                source="commercialAddressNeighborhood"
                                label="Bairro Comercial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.commercialAddressZipCode ? (
                            <Labeled>
                              <TextField
                                source="commercialAddressZipCode"
                                label="CEP Comercial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Stack>
                      <FunctionField
                        render={(record: any) =>
                          record.commercialPhoneNumber ? (
                            <Labeled>
                              <TextField
                                source="commercialPhoneNumber"
                                label="Telefone Comercial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.commercialAddressCity ? (
                            <Labeled>
                              <TextField
                                source="commercialAddressCity"
                                label="Cidade Comercial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <FunctionField
                        render={(record: any) =>
                          record.commercialAddressState ? (
                            <Labeled>
                              <TextField
                                source="commercialAddressState"
                                label="Estado Comercial"
                              />
                            </Labeled>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ padding: 2, marginBottom: 3 }}>
              <FunctionField
                render={(record: any) => (
                  <CardHeader
                    title="Divisões Técnicas"
                    // subheader={
                    //   "CPF: " +
                    //   record.cpf +
                    //   " - " +
                    //   "Matrícula: " +
                    //   record.registrationNumber
                    // }
                  />
                )}
              />

              <CardContent>
                <Typography variant="subtitle2">DTEs Atuais</Typography>
                <ArrayField label="DTEs" source="technicalDivisions">
                  <SingleFieldList linkType={false}>
                    <ChipField source="name" size="small" />
                  </SingleFieldList>
                </ArrayField>
              </CardContent>
            </Card>
            {(determineUserReadableRole(roles) === "admin" ||
              determineUserReadableRole(roles) === "finance" ||
              determineUserReadableRole(roles) === "secretary" ||
              determineUserReadableRole(roles) === "user") && (
              <Card
                sx={{
                  padding: 2,
                  marginBottom: 3,
                }}
              >
                <FunctionField
                  render={(record: any) => (
                    <CardHeader
                      title="Transações do Sócio"
                      // subheader={
                      //   "CPF: " +
                      //   record.cpf +
                      //   " - " +
                      //   "Matrícula: " +
                      //   record.registrationNumber
                      // }
                    />
                  )}
                />

                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <div
                          style={{
                            position: "relative",
                            overflow: "scroll",
                            maxWidth: "343px",
                          }}
                        > */}
                      <TableContainer
                        component={Paper}
                        // sx={{ overflow: "scroll", minWidth: "640px" }}
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              {determineUserReadableRole(roles) !== "user" && (
                                <TableCell>ID</TableCell>
                              )}
                              <TableCell>Valor</TableCell>
                              <TableCell>Competência</TableCell>
                              <TableCell>Data</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {transactions &&
                              transactions.length > 0 &&
                              transactions.map((transaction: any) => (
                                <TableRow key={transaction.id}>
                                  {determineUserReadableRole(roles) !==
                                    "user" && (
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                          determineUserReadableRole(roles) ===
                                          "secretary"
                                        }
                                        onClick={() => {
                                          window.location.href = `/#/transactions/crud/${transaction.id}/show`;
                                        }}
                                      >
                                        <HiEye />
                                      </Button>
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    R$ {transaction.value.toFixed(2)}
                                  </TableCell>
                                  {/* <TableCell>
                                  {transaction.type === "INCOME"
                                    ? "Receita"
                                    : "Despesa"}
                                </TableCell> */}
                                  <TableCell>
                                    {transaction.sCompetencia || "--"}
                                  </TableCell>
                                  <TableCell>
                                    {moment(transaction.referenceDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* </div> */}
                    </Grid>
                    {determineUserReadableRole(roles) !== "user" && (
                      <Grid item xs={12} sm={12}>
                        <FunctionField
                          render={(record: any) => (
                            <Button
                              variant="contained"
                              color="secondary"
                              disabled={
                                transactions.length === 0 ||
                                determineUserReadableRole(roles) === "secretary"
                              }
                              onClick={() => {
                                window.location.href =
                                  "/#/transactions/crud/?filter=%7B%22memberId%22%3A%22" +
                                  record.id +
                                  "%22%7D";
                              }}
                              fullWidth
                            >
                              Ver todas
                            </Button>
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </ShowContextProvider>
  );
};
