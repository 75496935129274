import {
  List,
  DatagridConfigurable,
  TextField,
  TextInput,
  NumberField,
  RichTextField,
  SelectField,
  SelectInput,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

export const ProductList = () => {
  const postFilters = [
    <TextInput label="Nome" source="name" alwaysOn />,
    <SelectInput
      source="status"
      label="Situação"
      choices={[
        { id: "active", name: "Ativo" },
        { id: "inactive", name: "Inativo" },
      ]}
    />,
  ];

  return (
    <>
      <PageTopBreadcrumb
        title="Itens de Cobrança"
        firstPath="/#/products/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List>
                <DatagridConfigurable>
                  <TextField source="id" label="ID" />
                  <TextField source="name" label="Nome" />
                  <NumberField source="price" label="Preço" />
                  <NumberField
                    source="maxInstallments"
                    label="Máximo de Parcelas"
                  />
                  {/* <RichTextField source="description" label="Descrição" /> */}
                  <SelectField
                    source="status"
                    label="Situação"
                    choices={[
                      { id: "active", name: "Ativo" },
                      { id: "inactive", name: "Inativo" },
                    ]}
                  />
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
