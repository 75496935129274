import { useEffect, useState } from "react";
import moment from "moment";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IuguPagination } from "./IuguPagination";
import PaymentIcon from "@mui/icons-material/Payment";
import { Typography } from "@mui/material";
import { statusLabels } from "./iugu-utils";
import { truncateString } from "../../../ra-components/users/UserUtils";

const IuguMyBillsPage = ({ props }: any) => {
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const deleteInvoice = async (id: string) => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;

    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/invoices/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ deleteInvoice: data });
    window.location.reload();
  };
  const getInvoices = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const start = (page - 1) * perPage;

    const responseUser = await fetch(
      `${process.env.REACT_APP_API_PATH}/users/crud/${authJson.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const dataUser = await responseUser.json();
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/my-invoices/${dataUser.iuguId}?s=${start}&l=${perPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ getInvoices: data });
    setInvoices(data.items);
    return data;
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <>
      <PageTopBreadcrumb
        title="Minhas Faturas"
        actionType="button"
        actionUrl="/#/my-invoices/new"
        actionText="Emitir Fatura"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          {/* {JSON.stringify(invoices)} */}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fatura</TableCell>
                <TableCell>Itens</TableCell>
                {/* <TableCell>Criada em</TableCell> */}
                {/* <TableCell>Datas</TableCell> */}
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invoices &&
                invoices.length > 0 &&
                invoices.map((invoice: any) => (
                  <TableRow key={invoice.id}>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={invoice.status !== "pending"}
                        size="small"
                        title={invoice.id}
                        onClick={() => {
                          // window.location.href =
                          //   "/#/invoices/crud/" + invoice.id + "/show";
                        }}
                      >
                        {truncateString(invoice.id, 13)}
                      </Button>
                      <br />
                      <Typography variant="caption">
                        <b>Situação:</b>
                        {statusLabels[invoice.status] || invoice.status}
                        <br />
                        <b>Emissão: </b>
                        {moment(invoice.createdAt).format("DD-MM-YYYY")}
                        <br />
                        <b>Vencimento:</b>{" "}
                        {invoice && invoice.due_date
                          ? moment(invoice.due_date).format("DD-MM-YYYY")
                          : "--/--/----"}
                      </Typography>
                      {/* {invoice.status} */}
                    </TableCell>
                    <TableCell>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell>Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoice.items &&
                            invoice.items.map((item: any) => (
                              <TableRow key={item.id}>
                                <TableCell>
                                  <Typography variant="caption">
                                    {item.description
                                      .replace("Subscription Activation: ", "")
                                      .replace(
                                        "Ativação de Assinatura: ",
                                        ""
                                      )}{" "}
                                    - x{item.quantity}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="caption">
                                    R${" "}
                                    {(parseInt(item.price_cents) / 100)
                                      .toFixed(2)
                                      .replace(".", ",")}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                <b>Total</b>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                <b>
                                  R${" "}
                                  {(parseInt(invoice.total_cents) / 100)
                                    .toFixed(2)
                                    .replace(".", ",")}
                                </b>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant={
                          invoice.status === "pending"
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          invoice.status === "pending" ? "success" : "info"
                        }
                        startIcon={<PaymentIcon />}
                        // disabled={invoice.status !== "pending"}
                        onClick={() => {
                          window.location.href = invoice.secure_url;
                        }}
                      >
                        Pagar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <IuguPagination setPage={setPage} page={page} list={invoices} />
    </>
  );
};

export default IuguMyBillsPage;
