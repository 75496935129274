import {
  EditContextProvider,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  useEditController,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";

export const ProductEdit = (props: any) => {
  const { id } = useParams();
  const controller = useEditController({
    resource: "products/crud",
    mutationMode: "pessimistic",
    id,
  });
  return (
    <EditContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Item de Cobrança"
          firstPath="/#/products/crud"
          secondTitle="Editando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="show"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Item de Cobrança" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <TextInput source="id" fullWidth />
                <TextInput source="name" fullWidth label="Nome" />
                <NumberInput
                  source="price"
                  label="Preço"
                  fullWidth
                  helperText="Use . para centavos"
                />
                <NumberInput
                  source="maxInstallments"
                  label="Máximo de Parcelas"
                  fullWidth
                />
                <RichTextInput
                  source="description"
                  label="Descrição"
                  fullWidth
                />
                <SelectInput
                  source="status"
                  label="Situação"
                  fullWidth
                  choices={[
                    { id: "active", name: "Ativo" },
                    { id: "inactive", name: "Inativo" },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </EditContextProvider>
  );
};
