import { useState } from "react";
import { FilterList, useListContext } from "react-admin";
import { Card, CardContent } from "@mui/material";
import { Button, ButtonGroup, Divider, Grid } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SortIcon from "@mui/icons-material/Sort";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const PostFilterSidebar = () => {
  const {
    displayedFilters,
    filterValues,
    setFilters,
    sort,
    setSort,
    hideFilter,
  } = useListContext();

  const userRole = localStorage.getItem("roles");

  const [dateField, setDateField] = useState("");
  const [dateFieldValue, setDateFieldValue] = useState<any>("");
  const [dateFilterType, setDateFilterType] = useState("");
  const [sortDateField, setSortDateField] = useState("createdAt");
  const [sortDateOrder, setSortDateOrder] = useState("DESC");
  // date fild defaults to now

  const submitSort = (e: any) => {
    setSort({ field: sortDateField, order: sortDateOrder });
  };
  const submitFilter = (e: any) => {
    localStorage.setItem("dateField", dateField);
    const newFilterValues = { ...filterValues };
    newFilterValues["dateField"] = dateField;
    newFilterValues["dateFilterType"] = dateFilterType;
    newFilterValues["dateFieldValue"] = dateFieldValue;
    // console.log("check sort", sort);
    setFilters(newFilterValues, {});

    // setMailName(e.target.value);
  };

  const form = useForm({
    defaultValues: filterValues,
  });
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      setFilters(values, {});
    } else {
      hideFilter("main");
    }
  };
  const resetFilter = () => {
    setFilters({}, []);
    localStorage.removeItem("dateField");
    setDateField("");
    setDateFilterType("");
    setDateFieldValue("");
    setSort({ field: "createdAt", order: "DESC" });
  };
  return (
    <Card
      sx={{
        // order: -1,
        mr: 2,
        // mt: 2,
        mb: 2,
        // border: "0 !important",
        // borderRadius: "0 !important",
        // boxShadow: "none !important",
      }}
    >
      <CardContent>
        {/* <SavedQueriesList /> */}
        {/* <FilterLiveSearch source="mailName" label="Nome/Email/CPF" /> */}
        {/* <TextInput label="Nome/Email/CPF" source="mailName" /> */}
        <>
          <Grid container>
            <Grid item xs={12} sm={6} p={1}>
              <FilterList label="FILTRO DE DATAS" icon={<FilterAltIcon />}>
                <Typography fontSize={11}>
                  Selecione um campo de data para filtrar
                </Typography>
                <Select
                  value={dateField}
                  label="Campo de Data"
                  onChange={(e) => setDateField(e.target.value as string)}
                  fullWidth
                >
                  <MenuItem value={"createdAt"}>Criação da conta</MenuItem>
                  <MenuItem value={"updatedAt"}>Atualização da conta</MenuItem>
                  <MenuItem value={"effectiveDate"}>Efetivo</MenuItem>
                  <MenuItem value={"admissionDate"}>Admissão</MenuItem>
                  <MenuItem value={"licenseDate"}>Licenciado</MenuItem>
                  <MenuItem value={"collectiveDate"}>Coletivo</MenuItem>
                  <MenuItem value={"remissionDate"}>Remido</MenuItem>
                  <MenuItem value={"aspirantDate"}>Aspirante</MenuItem>
                  <MenuItem value={"cancellationDate"}>Cancelado</MenuItem>
                  <MenuItem value={"contributionDate"}>Contribuinte</MenuItem>
                  <MenuItem value={"collectiveRemissionDate"}>
                    Coletivo Remido
                  </MenuItem>
                  <MenuItem value={"birthDate"}>Nascimento</MenuItem>
                  <MenuItem value={"deceasedDate"}>Falecimento</MenuItem>
                  <MenuItem value={"seniorDate"}>Sênior</MenuItem>
                </Select>
                {dateField && (
                  <>
                    <Typography fontSize={11}>
                      Selecione o tipo de filtragem
                    </Typography>
                    <Select
                      labelId="date-field-filter-type-label"
                      id="date-field-filter-type"
                      value={dateFilterType}
                      label="Tipo de Filtragem"
                      onChange={(e) =>
                        setDateFilterType(e.target.value as string)
                      }
                      fullWidth
                    >
                      <MenuItem value={"lte"}>Até dia X (lte)</MenuItem>
                      <MenuItem value={"gte"}>A partir do dia X (gte)</MenuItem>
                      <MenuItem value={"range"}>
                        Entre os dias X e Y (range)
                      </MenuItem>
                    </Select>
                  </>
                )}

                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="pt-br"
                >
                  {dateFilterType === "lte" && (
                    <DatePicker
                      label="Data até"
                      onChange={(v: any) => {
                        // console.log("v", v.format("YYYY-MM-DD"));

                        setDateFieldValue({
                          dateStart: null,
                          dateEnd: v.format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  )}
                  {dateFilterType === "gte" && (
                    <DatePicker
                      label="A partir de"
                      onChange={(v: any) =>
                        setDateFieldValue({
                          dateStart: v.format("YYYY-MM-DD"),
                          dateEnd: null,
                        })
                      }
                    />
                  )}
                  {dateFilterType === "range" && (
                    <>
                      <DatePicker
                        label="De"
                        onChange={(v: any) =>
                          setDateFieldValue({
                            dateStart: v.format("YYYY-MM-DD"),
                            dateEnd: dateFieldValue.dateEnd,
                          })
                        }
                      />
                      <DatePicker
                        label="Até"
                        onChange={(v: any) =>
                          setDateFieldValue({
                            dateStart: dateFieldValue.dateStart,
                            dateEnd: v.format("YYYY-MM-DD"),
                          })
                        }
                      />
                    </>
                  )}
                </LocalizationProvider>
                <Typography component={"div"} sx={{ lineHeight: 0.5 }}>
                  <br />
                </Typography>

                <ButtonGroup
                  disableElevation
                  variant="contained"
                  fullWidth
                  aria-label="Disabled elevation buttons"
                >
                  <Button
                    size="small"
                    onClick={submitFilter}
                    variant="contained"
                    startIcon={<ManageSearchIcon />}
                  >
                    Filtrar
                  </Button>
                  <Button
                    size="small"
                    onClick={resetFilter}
                    variant="outlined"
                    startIcon={<ClearIcon />}
                  >
                    Limpar
                  </Button>
                </ButtonGroup>
              </FilterList>
            </Grid>
            <Grid item xs={12} sm={6} p={1}>
              <FilterList label="ORDENAR POR DATAS" icon={<SortIcon />}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography fontSize={11}>
                      Selecione um campo para ordenar
                    </Typography>
                    <Select
                      labelId="date-sort-select-label"
                      id="date-sort-select"
                      value={sortDateField}
                      label="Ordenar por campo de data"
                      onChange={(e) =>
                        setSortDateField(e.target.value as string)
                      }
                      fullWidth
                    >
                      <MenuItem value={"createdAt"}>Criação da conta</MenuItem>
                      <MenuItem value={"updatedAt"}>
                        Atualização da conta
                      </MenuItem>
                      <MenuItem value={"effectiveDate"}>Efetivo</MenuItem>
                      <MenuItem value={"admissionDate"}>Admissão</MenuItem>
                      <MenuItem value={"licenseDate"}>Licenciado</MenuItem>
                      <MenuItem value={"collectiveDate"}>Coletivo</MenuItem>
                      <MenuItem value={"remissionDate"}>Remido</MenuItem>
                      <MenuItem value={"aspirantDate"}>Aspirante</MenuItem>
                      <MenuItem value={"cancellationDate"}>Cancelado</MenuItem>
                      <MenuItem value={"collectiveRemissionDate"}>
                        Coletivo Remido
                      </MenuItem>
                      <MenuItem value={"birthDate"}>Nascimento</MenuItem>
                      <MenuItem value={"deceasedDate"}>Falecimento</MenuItem>
                      <MenuItem value={"seniorDate"}>Sênior</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography fontSize={11}>Selecione a ordem</Typography>
                    <Select
                      labelId="date-sort-order-select-label"
                      id="date-sort-order-select"
                      value={sortDateOrder}
                      label="Ordenar por campo de data"
                      onChange={(e) =>
                        setSortDateOrder(e.target.value as string)
                      }
                      fullWidth
                    >
                      <MenuItem value={"DESC"}>Descendente</MenuItem>
                      <MenuItem value={"ASC"}>Ascendente</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Typography component={"div"} sx={{ lineHeight: 0.5 }}>
                  <br />
                </Typography>

                <ButtonGroup
                  disableElevation
                  variant="contained"
                  fullWidth
                  aria-label="Disabled elevation buttons"
                >
                  <Button
                    size="small"
                    onClick={submitSort}
                    variant="contained"
                    startIcon={<SortIcon />}
                  >
                    Ordenar
                  </Button>
                </ButtonGroup>
              </FilterList>
            </Grid>
          </Grid>
        </>
      </CardContent>
    </Card>
  );
};
