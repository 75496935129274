import React, { useEffect } from "react";
import "./App.css";
import { Admin, CustomRoutes, fetchUtils, Layout, Resource } from "react-admin";
import dataProvider from "ra-data-simple-rest";
import authProvider from "./AuthProvider";
import { AppSidebarMenu } from "./components/AppMenus";
import { AppNavbar } from "./components/AppNavbar";
import { UserList } from "./ra-components/users/UserList";
import { UserEdit } from "./ra-components/users/UserEdit";
import { UserShow } from "./ra-components/users/UserShow";
import { UserCreate } from "./ra-components/users/UserCreate";
import LoginPage from "./components/pages/Login";
import { TransactionsCreate } from "./ra-components/transactions/TransactionsCreate";
import { TransactionsShow } from "./ra-components/transactions/TransactionsShow";
import { TransactionsEdit } from "./ra-components/transactions/TransactionsEdit";
import { TransactionsList } from "./ra-components/transactions/TransactionsList";
import { FeedbacksCreate } from "./ra-components/feedbacks/FeedbacksCreate";
import { FeedbacksShow } from "./ra-components/feedbacks/FeedbacksShow";
import { FeedbacksEdit } from "./ra-components/feedbacks/FeedbacksEdit";
import { FeedbacksList } from "./ra-components/feedbacks/FeedbacksList";
import { UserRolesList } from "./ra-components/user-roles/UserRolesList";
import { UserRolesEdit } from "./ra-components/user-roles/UserRolesEdit";
import { UserRolesCreate } from "./ra-components/user-roles/UserRolesCreate";
import { UserRolesShow } from "./ra-components/user-roles/UserRolesShow";
import { DashboardPage } from "./components/pages/DashboardPage";
import SubscriptionPage from "./components/pages/iugu/SubscriptionPage";
import IuguCustomersPage from "./components/pages/iugu/IuguCustomersPage";
import IuguPlanosPage from "./components/pages/iugu/IuguPlanosPage";
import IuguCreatePlanosPage from "./components/pages/iugu/IuguCreatePlanosPage";
import { Route } from "react-router-dom";
import { ApiPage } from "./components/pages/ApiPage";
import { EducationalInstitutionList } from "./ra-components/educational-institutions/EducationalInstitutionList";
import { EducationalInstitutionEdit } from "./ra-components/educational-institutions/EducationalInstitutionEdit";
import { EducationalInstitutionShow } from "./ra-components/educational-institutions/EducationalInstitutionShow";
import { EducationalInstitutionCreate } from "./ra-components/educational-institutions/EducationalInstitutionCreate";
import { UserCategoryList } from "./ra-components/user-categories/UserCategoryList";
import { UserCategoryEdit } from "./ra-components/user-categories/UserCategoryEdit";
import { UserCategoryShow } from "./ra-components/user-categories/UserCategoryShow";
import { UserCategoryCreate } from "./ra-components/user-categories/UserCategoryCreate";
import { TechDivisionList } from "./ra-components/technical-divisions/TechDivisionList";
import { TechDivisionEdit } from "./ra-components/technical-divisions/TechDivisionEdit";
import { TechDivisionShow } from "./ra-components/technical-divisions/TechDivisionShow";
import { TechDivisionCreate } from "./ra-components/technical-divisions/TechDivisionCreate";
import { GraduationList } from "./ra-components/graduations/GraduationList";
import { GraduationEdit } from "./ra-components/graduations/GraduationEdit";
import { GraduationShow } from "./ra-components/graduations/GraduationShow";
import { GraduationCreate } from "./ra-components/graduations/GraduationCreate";
import { TransactionCategoryList } from "./ra-components/transaction-categories/TransactionCategoryList";
import { TransactionCategoryEdit } from "./ra-components/transaction-categories/TransactionCategoryEdit";
import { TransactionCategoryShow } from "./ra-components/transaction-categories/TransactionCategoryShow";
import { TransactionCategoryCreate } from "./ra-components/transaction-categories/TransactionCategoryCreate";
import englishMessages from "ra-language-english";
import polyglotI18nProvider from "ra-i18n-polyglot";
import IuguMyBillsPage from "./components/pages/iugu/IuguMyBillsPage";
import IuguMySubsPage from "./components/pages/iugu/IuguMySubsPage";
import { ProductList } from "./ra-components/products/ProductList";
import { ProductEdit } from "./ra-components/products/ProductEdit";
import { ProductCreate } from "./ra-components/products/ProductCreate";
import { ProductShow } from "./ra-components/products/ProductShow";
import IuguCreateBillPage from "./components/pages/iugu/IuguCreateBill";
import IuguCreateSubscriptionPage from "./components/pages/iugu/IuguCreateSubscription";
import { UserRoleStatusList } from "./ra-components/user-role-status/UserRoleStatusList";
import { UserRoleStatusEdit } from "./ra-components/user-role-status/UserRoleStatusEdit";
import { UserRoleStatusShow } from "./ra-components/user-role-status/UserRoleStatusShow";
import { UserRoleStatusCreate } from "./ra-components/user-role-status/UserRoleStatusCreate";
import { UserPositionRoleList } from "./ra-components/user-position-roles/UserPositionRoleList";
import { UserPositionRoleEdit } from "./ra-components/user-position-roles/UserPositionRoleEdit";
import { UserPositionRoleShow } from "./ra-components/user-position-roles/UserPositionRoleShow";
import { UserPositionRoleCreate } from "./ra-components/user-position-roles/UserPositionRoleCreate";
import { UserElectionList } from "./ra-components/user-elections/UserElectionList";
import { UserElectionEdit } from "./ra-components/user-elections/UserElectionEdit";
import { UserElectionShow } from "./ra-components/user-elections/UserElectionShow";
import { UserElectionCreate } from "./ra-components/user-elections/UserElectionCreate";
import {
  RecoveryPasswordPage,
  RegisterPageSuccess,
  RegisterPageV2,
} from "./components/pages/RegisterPageV2";
import { MailTemplateList } from "./ra-components/mail-templates/MailTemplateList";
import { MailTemplateEdit } from "./ra-components/mail-templates/MailTemplateEdit";
import { MailTemplateShow } from "./ra-components/mail-templates/MailTemplateShow";
import { MailTemplateCreate } from "./ra-components/mail-templates/MailTemplateCreate";
import { OrphanedUsersList } from "./ra-components/orphaned-users/OrphanedUsersList";
import { OrphanedUsersEdit } from "./ra-components/orphaned-users/OrphanedUsersEdit";
import { OrphanedUsersShow } from "./ra-components/orphaned-users/OrphanedUsersShow";
import { OrphanedUsersCreate } from "./ra-components/orphaned-users/OrphanedUsersCreate";
import { IuguInvoicesPage } from "./components/pages/iugu/IuguInvoicesPage";
import { SystemSettingList } from "./ra-components/system-settings/SystemSettingList";
import { SystemSettingEdit } from "./ra-components/system-settings/SystemSettingEdit";
import { SystemSettingShow } from "./ra-components/system-settings/SystemSettingShow";
import { SystemSettingCreate } from "./ra-components/system-settings/SystemSettingCreate";
import { determineUserReadableRole } from "./ra-components/users/UserUtils";
import { themeClean } from "./data/theme";
import { alterPortuguese } from "./data/i18n";
import WpLogin from "./components/pages/WpLogin";
import IuguClientIssueBill from "./components/pages/iugu/IuguClientIssueBill";

// console.log("lang ra", englishMessages);
const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "pt-br" ? alterPortuguese : englishMessages),
  "pt-br",
  [{ locale: "pt-br", name: "Português" }],
  {
    allowMissing: true,
  }
);

const isMobile = () => {
  if (window.outerWidth < 720) {
    localStorage.setItem("isMobile", "true");
  } else {
    localStorage.setItem("isMobile", "false");
  }
};

isMobile();
const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const auth = localStorage.getItem("auth");
  const authJson = auth ? JSON.parse(auth) : auth;
  if (auth) {
    const token = authJson.access_token;
    options.headers.set("Authorization", `Bearer ${token}`);
    const { status, headers, body, json } = await fetchUtils.fetchJson(
      url,
      options
    );
    return { status, headers, body, json };
  } else {
    const { status, headers, body, json } = await fetchUtils.fetchJson(
      url,
      options
    );
    return { status, headers, body, json };
  }
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.dataProvider = dataProvider(
      process.env.REACT_APP_API_PATH,
      httpClient
    );
  }
  render() {
    const roles = localStorage.getItem("roles");

    // console.log("loaded", localStorage.getItem("auth"));
    return (
      <Admin
        layout={(props) => (
          <Layout
            className={`mainLayout ${
              localStorage.getItem("isMobile") === "true"
                ? "isMobile"
                : "isNotMobile"
            }`}
            {...props}
            appBar={() => <AppNavbar />}
            menu={AppSidebarMenu}
          />
        )}
        loginPage={LoginPage}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        dataProvider={this.dataProvider}
        theme={themeClean}
        disableTelemetry
      >
        {(determineUserReadableRole(roles) === "admin" ||
          determineUserReadableRole(roles) === "finance" ||
          determineUserReadableRole(roles) === "secretary" ||
          determineUserReadableRole(roles) === "comms") && (
          <>
            <CustomRoutes>
              <Route path="/profile" component={""} />
              {(determineUserReadableRole(roles) === "admin" ||
                determineUserReadableRole(roles) === "finance") && (
                <>
                  <Route
                    path="/dashboard"
                    element={<DashboardPage props={this} />}
                  />
                  <Route path="/subscriptions" element={<SubscriptionPage />} />
                  <Route
                    path="/subscriptions/create"
                    element={<IuguCreateSubscriptionPage />}
                  />
                  <Route
                    path="/iugu-invoices/create"
                    element={<IuguCreateBillPage />}
                  />
                  <Route path="/iugu-planos" element={<IuguPlanosPage />} />
                  <Route
                    path="/iugu-planos/create"
                    element={<IuguCreatePlanosPage />}
                  />

                  <Route
                    path="/iugu-customers"
                    element={<IuguCustomersPage />}
                  />
                  <Route path="/iugu-invoices" element={<IuguInvoicesPage />} />
                </>
              )}
              {determineUserReadableRole(roles) === "admin" && (
                <Route path="/api" element={<ApiPage />} />
              )}
            </CustomRoutes>
            <Resource
              name="users/crud"
              {...((determineUserReadableRole(roles) === "admin" ||
                determineUserReadableRole(roles) === "finance" ||
                determineUserReadableRole(roles) === "secretary" ||
                determineUserReadableRole(roles) === "comms") && {
                list: UserList,
              })}
              {...(determineUserReadableRole(roles) === "admin" && {
                edit: UserEdit,
                create: UserCreate,
              })}
              show={UserShow}
              // edit={UserEdit}
              // list={UserList}
              // create={UserCreate}
            />

            {determineUserReadableRole(roles) === "admin" && (
              <>
                <Resource
                  name="orphaned-users/crud"
                  list={OrphanedUsersList}
                  edit={OrphanedUsersEdit}
                  create={OrphanedUsersCreate}
                  show={OrphanedUsersShow}
                />
                <Resource
                  name="mail-templates/crud"
                  list={MailTemplateList}
                  edit={MailTemplateEdit}
                  show={MailTemplateShow}
                  create={MailTemplateCreate}
                />
                <Resource
                  name="user-elections/crud"
                  list={UserElectionList}
                  create={UserElectionCreate}
                  edit={UserElectionEdit}
                  show={UserElectionShow}
                />
                <Resource
                  name="user-position-roles/crud"
                  list={UserPositionRoleList}
                  edit={UserPositionRoleEdit}
                  create={UserPositionRoleCreate}
                  show={UserPositionRoleShow}
                />
                <Resource
                  name="user-role-status/crud"
                  list={UserRoleStatusList}
                  edit={UserRoleStatusEdit}
                  show={UserRoleStatusShow}
                  create={UserRoleStatusCreate}
                />
                <Resource
                  name="user-roles/crud"
                  list={UserRolesList}
                  edit={UserRolesEdit}
                  show={UserRolesShow}
                  create={UserRolesCreate}
                />
                <Resource
                  name="technical-divisions/crud"
                  list={TechDivisionList}
                  edit={TechDivisionEdit}
                  show={TechDivisionShow}
                  create={TechDivisionCreate}
                />
                <Resource
                  name="user-categories/crud"
                  list={UserCategoryList}
                  edit={UserCategoryEdit}
                  show={UserCategoryShow}
                  create={UserCategoryCreate}
                />
                <Resource
                  name="graduations/crud"
                  list={GraduationList}
                  edit={GraduationEdit}
                  show={GraduationShow}
                  create={GraduationCreate}
                />
                <Resource
                  name="educational-institutions/crud"
                  list={EducationalInstitutionList}
                  edit={EducationalInstitutionEdit}
                  show={EducationalInstitutionShow}
                  create={EducationalInstitutionCreate}
                />
                <Resource
                  name="products/crud"
                  list={ProductList}
                  edit={ProductEdit}
                  show={ProductShow}
                  create={ProductCreate}
                />{" "}
                <Resource
                  name="system-settings/crud"
                  list={SystemSettingList}
                  edit={SystemSettingEdit}
                  show={SystemSettingShow}
                  create={SystemSettingCreate}
                />
              </>
            )}

            <Resource
              name="feedbacks/crud"
              list={FeedbacksList}
              edit={FeedbacksEdit}
              show={FeedbacksShow}
              create={FeedbacksCreate}
            />
            {(determineUserReadableRole(roles) === "admin" ||
              determineUserReadableRole(roles) === "secretary" ||
              determineUserReadableRole(roles) === "finance") && (
              <>
                <Resource
                  name="transaction-categories/crud"
                  list={TransactionCategoryList}
                  edit={TransactionCategoryEdit}
                  show={TransactionCategoryShow}
                  create={TransactionCategoryCreate}
                />
                <Resource
                  name="transactions/crud"
                  list={<TransactionsList dataProvider={this.dataProvider} />}
                  edit={TransactionsEdit}
                  show={TransactionsShow}
                  create={TransactionsCreate}
                />
              </>
            )}
          </>
        )}
        {determineUserReadableRole(roles) === "user" && (
          <>
            <CustomRoutes>
              <Route path="/profile" component={""} />
              <Route
                path="/my-invoices"
                element={<IuguMyBillsPage props={this} />}
              />
              <Route
                path="/my-subscriptions"
                element={<IuguMySubsPage props={this} />}
              />
              {/* <Route
                path="/dashboard"
                element={<DashboardPage props={this} />}
              /> */}
            </CustomRoutes>
            <Resource
              name="users/crud"
              // list={UserList}
              edit={UserEdit}
              show={UserShow}
              // create={UserCreate}
            />

            <Resource
              name="feedbacks/crud"
              // list={FeedbacksList}
              // edit={FeedbacksEdit}
              // show={FeedbacksShow}
              create={FeedbacksCreate}
            />
          </>
        )}
        <>
          <CustomRoutes>
            <Route path="/my-invoices/new" element={<IuguClientIssueBill />} />
            <Route path="/wp-login" element={<WpLogin />} />
            <Route path="/register" element={<RegisterPageV2 />} />
            <Route path="/register/success" element={<RegisterPageSuccess />} />
            <Route
              path="/account-recovery"
              element={<RecoveryPasswordPage />}
            />
            <Route
              path="/account-recovery/:token/*"
              element={<RecoveryPasswordPage />}
            />
          </CustomRoutes>
          <Resource
            name="feedbacks/crud"
            list={FeedbacksList}
            edit={FeedbacksEdit}
            show={FeedbacksShow}
            create={FeedbacksCreate}
          />
        </>
      </Admin>
    );
  }
}
